export const environment = {
  production: false,
  lockSite: false,
  serverTileUrl: 'https://staging.studio.stellar.online/',
  serverUrl: 'https://staging.api.stellar.online/api/',
  serverUrlNoSlash: 'https://staging.api.stellar.online/api',
  vimeoUrl: 'https://player.vimeo.com/video/',
  serverHostUrl: 'https://radio.interactivelife.me',
  serverredirectUrl: 'https://www.interactivelife.com/',
  s3BucketUrl: 'https://stellar-frontend-media.s3.amazonaws.com/',
  aliveAppIntervalMinutes: 90, // if you keep app inactive
  aliveCheckAppIntervalMinutes: 60, // if you keep app inactive pop a message
  repeatValidateSeconds: 30,
  hasListingDirectory: false,
  identityName: 'Radio',
  serverName: 'Radio',
  orgType: 'radio',
  orgSubType: 'radio',
  subServerName: 'Radio',
  profileImageURL: '',
  enableTranslation: true,
  customKeys: {
    roleAdmin: '5eb137f1d3123a407c7c97cc',// Admin
    roleView: '5ee76348d3123a0ef0d8cb63',// Viewer
    roleEdit: '5ee761c8d3123a0ef0d8cb61',// Editor
  },
  customKeysView: {
    Admin: 'Admin',
    Listener: 'View',
    'DJ': 'Edit',
  },
  tokBox: {
    apiKey: '46643372'
  },
  ipStack: {
    apiKey: '4e73b76fa09e6cfe386cf11e94c11e90'
  }
};
