<div fxFlex fxLayout="row" fxLayoutGap="10px" [style.font-size]="labelSize" *ngIf="showAudioVideoLabels">
    <div fxFlex="50">
        <span *ngIf="cameraOn">{{'Your camera is on' | translate}}</span>
        <span *ngIf="!cameraOn">{{'Your camera is off' | translate}}</span>
    </div>
    <div fxFlex="50" fxLayoutAlign="end">
        <span *ngIf="audioOn">{{'Your mic is on' | translate}}</span>
        <span *ngIf="!audioOn">{{'You are muted' | translate}}</span>
    </div>
</div>

<div class="aspect-container"
    [ngClass]="[(audioOn && cameraOn)? 'green-border': (audioOn || cameraOn)? 'orange-border': '']"
    (mouseenter)="toggleCameraActions(true)" (mouseleave)="toggleCameraActions(false)">
    <div class="overlay" *ngIf="name">{{name}}</div>
    <div #publisherCameraDiv id="{{prefixId}}{{id}}" class="aspect-container-element speaker-camera">
    </div>
    <div class="camera-actions" *ngIf="session && session['isConnected']() && !disableIcons">
        <div class="play-button float-left" [style.cursor]="disableIcon? 'not-allowed':''">
            <div *ngIf="!cameraOn" title="{{'Turn on camera' | translate}}">
                <a (click)="toggleCamera(true)">
                    <mat-icon [style.font-size]="iconSize">videocam</mat-icon>
                </a>
            </div>
            <div *ngIf="cameraOn" title="{{'Turn off camera' | translate}}" class="pause-button"
                [class.hover]="hovered">
                <a (click)="toggleCamera(false)">
                    <mat-icon [style.font-size]="iconSize" style="color: lime;">videocam</mat-icon>
                </a>
            </div>
        </div>
        <div class="play-button float-right" [style.cursor]="disableIcon? 'not-allowed':''">
            <div *ngIf="!audioOn" title="{{'Turn on audio' | translate}}">
                <a (click)="toggleAudio(true)">
                    <mat-icon [style.font-size]="iconSize">mic</mat-icon>
                </a>
            </div>
            <div *ngIf="audioOn" title="{{'Turn off audio' | translate}}" class="pause-button" [class.hover]="hovered">
                <a (click)="toggleAudio(false)">
                    <mat-icon [style.font-size]="iconSize" style="color:lime">mic</mat-icon>
                </a>
            </div>
        </div>
    </div>
</div>