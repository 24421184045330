import { Injectable, ElementRef, Renderer2, NgZone, ChangeDetectorRef } from '@angular/core';

import * as OT from '@opentok/client';
import { environment } from '../../../environments/environment';
import { LayoutUtilsService } from './utils/layout-utils.service';

import { BehaviorSubject, timer } from 'rxjs';
import { RequestService } from './request.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmEntityDialogComponent } from '../components/modals/confirm-entity-dialog/confirm-entity-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NotificationComponent } from '../components/notification/notification.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { DefaultSettings } from '../components/layout-components/defaultSettings';

@Injectable()
export class TokBoxService {

  _userCapabilitiesMap: BehaviorSubject<Map<string, object>> = new BehaviorSubject<Map<string, object>>(undefined);
  sortedAttendeeMap: BehaviorSubject<Map<string, object>> = new BehaviorSubject<Map<string, object>>(undefined);
  globalSession: OT.Session;
  zIndexDraggable: BehaviorSubject<number> = new BehaviorSubject<number>(5);
  userListJoinedGlobally: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  isOpenGlobalChat: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(undefined);
  _isOpenGlobalChat: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(undefined);
  isOpenAgenda: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(undefined);
  _isOpenAgenda: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(undefined);
  chatMessages: object[] = [];
  unreadChats: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  chatPrivatelyWith: any = undefined;
  _dndChat: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(undefined);
  _isTextChat: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(undefined);
  _isVideoChat: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(undefined);
  userFlags: any = {};
  _videoCall: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  _videoCallResponse: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  _hideDrawer: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(undefined);
  chatPrivatelyWithSubject: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  _playRingTone: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  _answerCall: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  _declineCall: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  onGoingCall: string = undefined;
  userJoinedSession: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  attendeeJoinedSession: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  unmuteDialog: any = undefined;
  connectionHealth: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  videoChatInvite: boolean = false;
  lastChatMessage: any = { message: '' };
  _joinUserSession: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  currentUserFlags: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  controlThisStream: BehaviorSubject<OT.Stream> = new BehaviorSubject<OT.Stream>(undefined);
  controlGuestJoined: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  leadCameraDivSnapTo: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  muteAll: boolean = false;
  highlightNotifications: BehaviorSubject<any> = new BehaviorSubject<boolean>(undefined);
  controlHLS: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  broadcastDelay: number = 20 * 1000; // delay from 15 to 20 seconds for broadcast to be ready
  breakoutGroups: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  byPassCheckBreakout: boolean = false;
  // breakoutStatus: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);

  constructor(private layoutService: LayoutUtilsService, private requestService: RequestService, private dialog: MatDialog, private translate: TranslateService, private zone: NgZone, private router: Router, public _bottomSheet: MatBottomSheet) { }

  getOT() {
    return OT;
  }

  initSession(sessionId: string) {
    //session is a room
    //console.log(sessionId)
    if (environment.tokBox.apiKey) {
      let session = this.getOT().initSession(environment.tokBox.apiKey, sessionId);
      return Promise.resolve(session);
    }
  }

  connect(token: string, session: OT.Session) {
    //console.log(token);
    return new Promise((resolve, reject) => {
      session.connect(token, (error) => {
        if (error) {
          this.handleError(error);
          //reject(error);
        } else {
          resolve(session);
        }
      });
    });
  }

  public handleError(errorCode: Object) {
    //console.log(errorCode);
    switch (errorCode['name']) {
      case 'OT_AUTHENTICATION_ERROR':
        if (errorCode['code'] == 1004) {
          this.layoutService.showNotificationSnack('Authentication error. Refresh the page.', 'Dismiss');
        }
        else {
          this.layoutService.showNotificationSnack('Authentication error.', 'Dismiss');
        }
        break;
      case 'OT_SCREEN_SHARING_NOT_SUPPORTED':
        this.layoutService.showNotificationSnack('Unable to share screen.', 'Dismiss');
        break;
      case 'OT_CREATE_PEER_CONNECTION_FAILED':
        this.layoutService.showNotificationSnack('Client unable to join session.', 'Dismiss');
        break;
      case 'OT_SCREEN_SHARING_NOT_SUPPORTED':
        this.layoutService.showNotificationSnack('Screen sharing is not supported.', 'Dismiss');
        break;
      case 'OT_SCREEN_SHARING_EXTENSION_NOT_INSTALLED':
      case 'OT_SCREEN_SHARING_EXTENSION_NOT_REGISTERED':
        this.layoutService.showNotificationSnack('Screen sharing requires a type extension, but it is not installed.', 'Dismiss');
        break;
      case 'OT_USER_MEDIA_ACCESS_DENIED':
        this.layoutService.showNotificationSnack('Allow access and try publishing again.', 'Dismiss');
        break;
      case 'OT_NO_DEVICES_FOUND':
        this.layoutService.showNotificationSnack('You do not have a microphone attached to your computer.', 'Dismiss');
        break;
      case 'OT_NOT_CONNECTED':
        this.layoutService.showNotificationSnack('Session is not connected yet.', 'Dismiss');
        break;
      case 'OT_SOCKET_CLOSE_TIMEOUT':
      case 'OT_UNEXPECTED_SERVER_RESPONSE':
        this.layoutService.showNotificationSnack('No connection. Reloading the page.', 'Dismiss');
        setTimeout(() => window.location.reload(), 5000);
        break;
      case 'OT_HARDWARE_UNAVAILABLE':
        this.layoutService.showNotificationSnack('Audio/Video hardware is not available.', 'Dismiss');
        break;
      case 'OT_STREAM_DESTROYED':
        break;
      default:
        this.handleErrorByCode(errorCode);
        break;
    }
  }

  public handleErrorByCode(errorCode: Object) {
    switch (errorCode['code'] + '') {
      case '1013':
        this.layoutService.showNotificationSnack('Blocked by firewall', 'Dismiss');
        break;
      case '1004':
        this.layoutService.showNotificationSnack('Authentication error, Token is invalid. ', 'Dismiss');

        break;
      case '1005':
        this.layoutService.showNotificationSnack('Authentication error, Session is invalid.', 'Dismiss');
        break;
      case '1006':
        this.layoutService.showNotificationSnack('Connection failed, check if you have internet connection.', 'Dismiss');
        break;
      case '1010':
        this.layoutService.showNotificationSnack('Cannot publish: the client is not connected to the session.', 'Dismiss');
        break;
      case '1500':
        this.layoutService.showNotificationSnack('Unable to Publish.', 'Dismiss');
        break;
      case '1501':
        this.layoutService.showNotificationSnack('Slow internet connection.', 'Dismiss');
        break;
      case '1510':
        this.layoutService.showNotificationSnack('Unable to send message.', 'Dismiss');
        break;
      case '1004':
        this.layoutService.showNotificationSnack('Authentication error. Check token expiry.', 'Dismiss');
        break;
      default:
        // this.layoutService.showNotificationSnack('Slow connection.', 'Dismiss');
        break;
    }
  }

  public sendText(message: string, session: OT.Session) {
    if (message && message.trim() != '' && session) {
      session.signal(
        {
          data: message,
          type: 'textMessage'
        },
        function (error) {
          if (error) {
            //console.log("signal error ("
            // + error.name
            //   + "): " + error.message);
          } else {
            //message = '';
            //console.log("signal sent.");
          }
        }
      );
    }
  }

  public hashCode(str) {
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    return hash;
  }

  // public intToRGB(i) {
  //   var c = (i & 0x00FFFFFF)
  //     .toString(16)
  //     .toUpperCase();

  //   return "00000".substring(0, 6 - c.length) + c;
  // }

  public colorLuminance(hex, lum) {

    // validate hex string
    hex = String(hex).replace(/[^0-9a-f]/gi, '');
    if (hex.length < 6) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    lum = lum || 0;

    // convert to decimal and change luminosity
    var rgb = "#", c, i;
    for (i = 0; i < 3; i++) {
      c = parseInt(hex.substr(i * 2, 2), 16);
      c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16);
      rgb += ("00" + c).substr(c.length);
    }

    return rgb;
  }

  public dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/jpeg' });
    return blob;
  }

  public sendSignal(type: string, message: string, currentSession: OT.Session, recepientConnection?: OT.Connection) {
    if (message && message.trim() != '' && currentSession) {
      let signalData;
      if (recepientConnection) {
        signalData = {
          to: recepientConnection,
          data: message,
          type: type
        }
      }
      else {
        signalData = {
          data: message,
          type: type
        }
      }
      return new Promise((resolve, reject) => currentSession.signal(signalData, (error) => {
        if (error) {
          reject();
        } else {
          resolve(true);
        }
      }));
    }
  }


  public unPublishScreenAudioAndCamera(session: OT.Session, screenPublisher: OT.Publisher, mediaPublisher?: OT.Publisher) {
    if (session) {
      if (screenPublisher) {
        session.unpublish(screenPublisher);
      }
      if (mediaPublisher) {
        session.unpublish(mediaPublisher);
      }
    }
  }

  public unPublishPublisher(session: OT.Session, publisher: OT.Publisher): any {
    if (session) {
      if (publisher) {
        session.unpublish(publisher);
      }
    }
    return null;
  }

  public disconnectSession(session: OT.Session) {
    try {
      if (session) {
        session.off();
        session.disconnect();
      }
    }
    catch (e) {

    }
  }

  public unSubscribeScreenAudioAndCamera(subscribers: Array<OT.Subscriber>, session: OT.Session) {
    if (session) {
      for (let streamId in subscribers) {
        //console.log('unsubscribing now', streamId, subscribers[streamId])
        session.unsubscribe(subscribers[streamId]);
        subscribers[streamId].off();
      }
    }
  }

  public unSubscribe(subscriber: OT.Subscriber, session: OT.Session) {
    try {
      if (subscriber && subscriber.stream) {
        session.unsubscribe(subscriber);
        subscriber.off();
      }
    }
    catch (e) {
      // console.log('while unsubscribing', subscriber)
    }
  }

  speakerDetection(subscriber, startTalking, stopTalking) {
    let activity = null;
    subscriber.on('audioLevelUpdated', (event) => {
      let now = Date.now();
      if (event.audioLevel > 0.1) {
        // console.log('event.audioLevel', event.audioLevel)
        if (!activity) {
          activity = { timestamp: now, talking: false };
        } else if (activity.talking) {
          activity.timestamp = now;
        } else if (now - activity.timestamp > 2000) {
          // detected audio activity for more than 2s
          // for the first time.
          activity.talking = true;
          if (typeof (startTalking) === 'function') {
            startTalking(event);
          }
        }
      } else if (activity && now - activity.timestamp > 3000) {
        // detected low audio activity for more than 3s
        if (activity.talking) {
          if (typeof (stopTalking) === 'function') {
            stopTalking(event);
          }
        }
        activity = null;
      }
    });
  };

  set userCapabilitiesMap(map: any | undefined) {
    //console.log('map', map);
    this._userCapabilitiesMap.next(map);
  }

  get userCapabilitiesMap(): any | undefined {
    //console.log('mthis._userCapabilitiesMapap', this._userCapabilitiesMap);
    return this._userCapabilitiesMap;
  }

  reOrderMap(studentRequests: Map<any, any>, data: object): Map<any, any> {
    //reorder studentRequests
    let tempObj = studentRequests.get(data['userId']);
    studentRequests.delete(data['userId']);
    const arr = Array.from(studentRequests);
    arr.splice(1, 0, [data['userId'], tempObj]);
    studentRequests.clear();
    arr.forEach(([k, v]) => studentRequests.set(k, v));
    this.sortedAttendeeMap.next(studentRequests);
    return studentRequests;
  }

  dynamicSort(property) {
    var sortOrder = 1;

    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }

    return (a, b) => {
      if (sortOrder == -1) {
        return b[property].localeCompare(a[property]);
      } else {
        return a[property].localeCompare(b[property]);
      }
    }
  }

  deleteEntryFromMap(map: Map<any, any>, toDelete: string, renderer: Renderer2) {
    this.removeOverlayName(toDelete, renderer);

    Object.keys(map).forEach((key) => {
      if (key == toDelete)
        delete map[key];
    });
  }

  removeOverlayName(toDelete: string, renderer: Renderer2) {
    document.querySelectorAll('[data-id*="' + toDelete + '"').forEach(element => {
      renderer.removeChild(document.body, element);
    });
  }

  switchDisplay(user: object, element: ElementRef, renderer: Renderer2) {
    element.nativeElement.querySelectorAll('[data-type="media"]').forEach(element => {
      renderer.setStyle(element, 'display', 'none');
    });
    element.nativeElement.querySelectorAll('[data-id^="name-"]').forEach(element => {
      renderer.setStyle(element, 'display', 'none');
    });

    if (user['streams']['media']) {
      let elem = element.nativeElement.querySelector('[data-id="' + user['streams']['media']['streamId'] + '"]');
      if (elem)
        renderer.removeStyle(elem, 'display');
      if (user['streams']['media'].hasVideo) {
        elem = element.nativeElement.querySelector('[data-id="name-' + user['streams']['media']['streamId'] + '-corner"]');
        if (elem)
          renderer.removeStyle(elem, 'display');
      }
      else {
        elem = element.nativeElement.querySelector('[data-id="name-' + user['streams']['media']['streamId'] + '"]');
        if (elem)
          renderer.removeStyle(elem, 'display');
      }
    }
  }

  getCircularReplacer = () => {
    const seen = new WeakSet();
    return (key, value) => {
      if (typeof value === "object" && value !== null) {
        if (seen.has(value)) {
          return;
        }
        seen.add(value);
      }
      return value;
    };
  }

  getRandomInt(max) {
    return Math.floor(Math.random() * Math.floor(max));
  }

  generateRandomId(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  streamChanged(event: any, renderer: Renderer2, attendeeMap: any) {
    let stream: OT.Stream = event.stream;
    let data = JSON.parse(event.stream.connection.data);
    let userObj = attendeeMap.get(data['userId']);

    if (event.changedProperty == 'hasVideo') {
      if (event.newValue) {
        let element = document.querySelector('[data-id="name-' + stream.streamId + '"]');
        if (element)
          renderer.setStyle(element, 'display', 'none');
        element = document.querySelector('[data-id="name-' + stream.streamId + '-corner"]');
        if (element)
          renderer.removeAttribute(element, 'style');
      }
      else {
        let element = document.querySelector('[data-id="name-' + stream.streamId + '-corner"]');
        if (element)
          renderer.setStyle(element, 'display', 'none');
        element = document.querySelector('[data-id="name-' + stream.streamId + '"]');
        if (element)
          renderer.removeAttribute(element, 'style');
      }

      // userObj['audioOnOff'] = event.stream.hasAudio;
      // userObj['sharingVideo'] = event.stream.hasVideo;

    }
    else if (event.changedProperty == 'hasAudio') {
      userObj['audioOnOff'] = event.stream.hasAudio;
    }

    attendeeMap.set(data['userId'], userObj);
    this.userCapabilitiesMap = attendeeMap;
  }

  subscribeToMedia(stream: OT.Stream, container: any, session: OT.Session, renderer: Renderer2, retryInSec: number, cameraResolution, insertMode: any, callBack?: () => void, subscribetoCamera?: boolean, firstUser?: boolean, hideOverlay?: boolean, errorCallBack?: (error) => void): OT.Subscriber {
    //console.log('camera stream', stream);
    let data = JSON.parse(stream.connection.data);
    // renderer.setProperty(container.nativeElement, 'innerHTML', '');

    let element = renderer.createElement('div');
    renderer.setStyle(element, 'height', '100%');
    renderer.setStyle(element, 'width', '100%');
    renderer.setAttribute(element, 'data-type', 'media');
    renderer.setAttribute(element, 'data-id', stream.streamId);
    //console.log('first user', firstUser)
    if (!firstUser) {
      renderer.setStyle(element, 'display', 'none');
    }

    let overlayElement, nameElement;
    if (!hideOverlay) {
      overlayElement = renderer.createElement('div');
      renderer.setAttribute(overlayElement, 'class', 'name-overlay-container');
      renderer.setAttribute(overlayElement, 'data-id', 'name-' + stream.streamId);
      nameElement = renderer.createElement('div');
      renderer.setProperty(nameElement, 'innerHTML', data['name']);
      if (stream.hasVideo || !firstUser) {
        renderer.setStyle(overlayElement, 'display', 'none');
      }
      overlayElement.append(nameElement);
      container.prepend(overlayElement);

      overlayElement = renderer.createElement('div');
      renderer.setAttribute(overlayElement, 'class', 'name-overlay-container-corner');
      renderer.setAttribute(overlayElement, 'data-id', 'name-' + stream.streamId + '-corner');
      nameElement = renderer.createElement('div');
      renderer.setProperty(nameElement, 'innerHTML', data['name']);
      if (!stream.hasVideo || !firstUser) {
        renderer.setStyle(overlayElement, 'display', 'none');
      }
      overlayElement.append(nameElement);
      container.prepend(overlayElement);
    }

    container.append(element);

    let userImage = '';
    if (data.hasOwnProperty('userImage') && data['userImage']) {
      userImage = data['userImage'];
    }
    else {
      userImage = this.requestService.serverHostUrl + '/assets/images/defaultattendee.png';
    }

    let timer;
    let subscriber: OT.Subscriber;
    subscriber = session.subscribe(stream, element, { width: '100%', height: '100%', insertMode: insertMode, style: { backgroundImageURI: userImage, nameDisplayMode: 'off', buttonDisplayMode: 'off', audioLevelDisplayMode: 'off', audioBlockedDisplayMode: 'off', videoDisabledDisplayMode: 'off' }, subscribeToAudio: true, subscribeToVideo: subscribetoCamera }, (error) => {
      //preferredResolution: this.cameraResolution
      if (error) {
        if (errorCallBack && error && error.hasOwnProperty('name') && error.name === 'OT_STREAM_LIMIT_EXCEEDED')
          errorCallBack(error);
        if (overlayElement)
          renderer.removeChild(document.body, overlayElement);
        if (nameElement)
          renderer.removeChild(document.body, nameElement);
        this.handleError(error);
        if (error['code'] == 1501) {
          timer = setTimeout(() => this.subscribeToMedia(stream, container, session, renderer, retryInSec, cameraResolution, insertMode), retryInSec * 1000);
        }
      }
      else {
        // let audioCtx = new AudioContext();
        // audioCtx.resume();
        clearTimeout(timer);
        if (callBack) {
          callBack();
        }
      }
    });
    // subscriber.restrictFrameRate(true);
    subscriber.on("videoDisabled", (event) => {
      console.log('video is disabled', event);
      // this.videoDisabled();
    });
    subscriber.on("videoDisableWarning", (event) => {
      console.log('video warning');
      this.connectionHealth.next(false);
      // this.videoWarning(subscriber, { width: 320, height: 240 });
    });
    subscriber.on("videoDisableWarningLifted", (event) => {
      console.log("video warning lifted");
      this.connectionHealth.next(true);
    });
    subscriber.on("videoEnabled", (event) => {
      console.log("video enabled");
    });
    return subscriber;
  }

  showBottomDialog(data: string) {
    this.zone.run(() => {
      if (typeof data == 'string')
        this._bottomSheet.open(NotificationComponent, { data: { title: 'Announcement', content: data, closeButton: { showCloseButton: true, label: 'Close' } }, disableClose: true });
      else {
        let link = '';
        if (data['link']) {
          link = '/rooms/' + data['link']['roomId'] + '/sessions/' + data['link']['sessionId'];
        }
        this._bottomSheet.open(NotificationComponent, { data: { title: 'Announcement', content: data['message'], closeButton: { showCloseButton: true, label: 'Close' }, secondButton: { label: 'Teleport Me', link: link } }, disableClose: true });
      }
    });
    // if (typeof data == 'string')
    //   this.showNotificationDialog(data, '');
    // else {
    //   let link = '';
    //   if (data['link']) {
    //     link = '/#/rooms/' + data['link']['roomId'] + '/sessions/' + data['link']['sessionId'];
    //   }
    //   this.showNotificationDialog(data['message'], link);
    // }
  }

  showNotificationDialog(message: string, link: string) {
    this.zone.run(() => {
      let dataObj = {
        title: 'Announcement',
        data: '',
        description: message,
        cancelbtn: this.translate.instant('Close')
      };
      if (link) {
        dataObj['confirmbtn'] = this.translate.instant('Teleport me');
      }
      const dialogRef = this.dialog.open(ConfirmEntityDialogComponent, {
        disableClose: true,
        data: dataObj,
        width: '40vw'
      });
      if (link)
        dialogRef.afterClosed().subscribe(result => {
          if (result !== undefined) {
            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
              this.router.navigate([link]));
          }
        });
    });
  }

  switchToNextUser(container: ElementRef, attendeeMap: any, renderer: Renderer2, subscribers: any, currentUserId: string): string {
    //only if attendee
    // let userRole = this.requestService.getSessionRoleByUser(this.sessionData, userId);
    let switchToThisUser = null;
    let switchToThisUserId = null;
    attendeeMap.forEach((attendee: object, id: string) => {
      if (attendee['streams']['media'] && !switchToThisUser && (attendee['role'] === 'attendee' || attendee['role'] === 'anonymous')) {
        switchToThisUser = attendee;
        switchToThisUserId = id;
      }
    });
    let speakingNow = undefined;
    if (switchToThisUser) {
      if (switchToThisUser['streams']['media']) { // if who is speaking has a camera switched on
        // obj.subscribeToMedia(switchToThisUser['streams']['media'], this.publisherCamera_component.publisherCameraDiv, true, () => {
        speakingNow = switchToThisUserId;
        if (attendeeMap.has(speakingNow)) {
          let userTurnOn = attendeeMap.get(speakingNow);
          if (userTurnOn && speakingNow != currentUserId && subscribers[userTurnOn['streams']['media']['streamId']])
            subscribers[userTurnOn['streams']['media']['streamId']].subscribeToVideo(true);
          this.switchDisplay(userTurnOn, container, renderer);
        }
        // });
      }
      else {
        speakingNow = switchToThisUserId;
      }
    }
    else {
      speakingNow = undefined;
    }

    return speakingNow;
  }

  switchToNextGuest(container: ElementRef, attendeeMap: any, renderer: Renderer2, subscribers: any, currentUserId: string): string {
    //only if attendee
    // let userRole = this.requestService.getSessionRoleByUser(this.sessionData, userId);
    let switchToThisUser = null;
    let switchToThisUserId = null;
    attendeeMap.forEach((attendee: object, id: string) => {
      if (attendee['streams']['media'] && !switchToThisUser && attendee['role'] === 'guest') {
        switchToThisUser = attendee;
        switchToThisUserId = id;
      }
    });
    let speakingNow = undefined;
    if (switchToThisUser) {
      if (switchToThisUser['streams']['media']) { // if who is speaking has a camera switched on
        // obj.subscribeToMedia(switchToThisUser['streams']['media'], this.publisherCamera_component.publisherCameraDiv, true, () => {
        speakingNow = switchToThisUserId;
        if (attendeeMap.has(speakingNow)) {
          let userTurnOn = attendeeMap.get(speakingNow);
          if (userTurnOn && speakingNow != currentUserId && subscribers[userTurnOn['streams']['media']['streamId']])
            subscribers[userTurnOn['streams']['media']['streamId']].subscribeToVideo(true);
          this.switchDisplay(userTurnOn, container, renderer);
        }
        // });
      }
      else {
        speakingNow = switchToThisUserId;
      }
    }
    else {
      speakingNow = undefined;
    }

    return speakingNow;
  }

  extractYoutubeVideoId(videoUrl: string): string {
    let id;
    if (videoUrl) {
      let url = videoUrl.replace(/(>|<)/gi, '').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
      if (url[2] !== undefined) {
        id = url[2].split(/[^0-9a-z_\-]/i);
        id = id[0];
      }
      else {
        id = url;
      }
    }
    return id;
  }

  extractVimeoVideoId(videoUrl: string): string {
    let regExp = /(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^\/]*)\/videos\/|album\/(?:\d+)\/video\/|video\/|)(\d+)(?:[a-zA-Z0-9_\-]+)?/i;

    if (videoUrl) {
      let match = videoUrl.match(regExp);

      if (match.length >= 2) {
        return match[1];
      }
      else {
        return null;
      }
    }
    return null;
  }

  cleanTime(player) {
    if (player)
      return Math.round(player.getCurrentTime())
    return null;
  }

  time_convert(num) {
    let sec_num = parseInt(num, 10);
    let hours = Math.floor(sec_num / 3600);
    let minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    let seconds = sec_num - (hours * 3600) - (minutes * 60);

    let secondsSt, minutesSt, hoursSt;
    if (hours < 10) {
      hoursSt = "0" + hours;
    }
    else
      hoursSt = hours;
    if (minutes < 10) {
      minutesSt = "0" + minutes;
    }
    else
      minutesSt = minutes;
    if (seconds < 10) {
      secondsSt = "0" + seconds;
    }
    else
      secondsSt = seconds;
    // debugger;
    return hoursSt + 'h' + minutesSt + 'm' + secondsSt + 's';
  }

  initYoutube() {
    let tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/player_api';
    let firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
  }

  initVimeo() {
    let tag = document.createElement('script');
    tag.src = 'https://player.vimeo.com/api/player.js';
    let firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
  }

  formatAMPM(date) {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    let strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }

  videoWarning(subscriber: OT.Subscriber, preferredResolution) {
    // subscriber.setPreferredResolution(preferredResolution);
    this.layoutService.showNotificationSnack(this.translate.instant('Low internet connection detected - the video may be disabled'), this.translate.instant('Dismiss'));
  }

  videoDisabled() {
    this.layoutService.showNotificationSnack(this.translate.instant('The video was disabled due to a bad internet connection'), this.translate.instant('Dismiss'));
  }

  // videoWarningLifted(){
  //   this.layoutService.showNotificationSnack(this.translate.instant('Due to low internet bandwidth, the video will be disabled'), this.translate.instant('Dismiss'));
  // }
  showUnBlockAudioDialog() {
    if (!this.unmuteDialog) {
      this.zone.run(() => {
        let alertSetting = {};
        alertSetting['icon'] = 'campaign';
        alertSetting['overlayClickToClose'] = false;
        alertSetting['showCloseButton'] = false;
        alertSetting['confirmText'] = 'Unmute';
        alertSetting['buttonColor'] = 'primary';

        this.unmuteDialog = this.layoutService.alertActionElement('', this.translate.instant("Unmute Speaker Audio"), alertSetting);
        this.unmuteDialog.afterClosed().subscribe(res => {
          if (res) {
            OT.unblockAudio();
            this.unmuteDialog = undefined;
          }
        });
      });
    }
  }

  getCounter(tick) {
    return timer(0, tick);
  }

  getVideoCallCounter(tick) {
    return timer(0, tick);
  }

  setLayoutSettings(sessionData, isSessionActive, relativeToWidth?: number) {
    let layoutSettings, defaultMessage;
    let questionsElement: any = { disable: true, backgroundColor: undefined, height: undefined };
    let chatElement: any = { disable: true, backgroundColor: undefined, height: undefined };
    let hasCamera = false;
    // let guestNames: any = [];

    if (sessionData && sessionData.settings.hasOwnProperty('version') && sessionData.settings['version'] === DefaultSettings.defaultSetting.version) {
      if (!isSessionActive && sessionData.settings.hasOwnProperty('desktop-offline'))
        layoutSettings = sessionData.settings['desktop-offline'];
      else if (sessionData.streamMode === 'hls' && sessionData.settings.hasOwnProperty('desktop-hls') && sessionData.settings['desktop-hls'])
        layoutSettings = sessionData.settings['desktop-hls'];
      else if (sessionData.settings.hasOwnProperty('desktop') && sessionData.settings.desktop)
        layoutSettings = sessionData.settings.desktop;

      if (layoutSettings) {
        if (layoutSettings.hasOwnProperty('optionSettings')) {
          if (layoutSettings.optionSettings.hasOwnProperty('enableAskQuestions') && layoutSettings.optionSettings.enableAskQuestions) {
            questionsElement.disable = false;
          }
          if (layoutSettings.optionSettings.hasOwnProperty('enableChat') && layoutSettings.optionSettings.enableChat) {
            chatElement.disable = false;
          }
        }

        if (layoutSettings.hasOwnProperty('columns')) {
          layoutSettings['columns'].forEach(element => {
            element.components.forEach(obj => {
              if (relativeToWidth) {
                // let getRatio = obj.w / obj.h;
                obj.w = obj.w * relativeToWidth / document.body.offsetWidth;
                // obj.h = obj.w /  getRatio;
              }
              // if (obj.name == 'tile' && obj.hasOwnProperty('metadata') && obj.metadata.hasOwnProperty('id') && obj.metadata.id) {
              //   obj.metadata['frameLink'] = this.requestService.getTileLink(sessionData._id, obj.metadata.id, false);
              //   if (!obj.height)
              //     obj.height = '60vh';
              // }
              // else if (obj.name === 'screenshare') {
              if (obj.name === 'screenshare') {
                defaultMessage = obj.message;
              }
              else if (obj.name === 'defaultvideo' && obj.hasOwnProperty('autoStart') && obj.autoStart) {
                obj.url = obj.url + '?&autoplay=1&mute=1';
              }
              else if (obj.name === 'camera')
                hasCamera = true;
              // else if (obj.name === 'guest-camera' && obj.metadata.id) {
              //   guestNames.push({ id: obj.metadata.id, name: obj.metadata.name });
              // }
              // else if (obj.name === 'chat' && obj.active && !leadInSimpleView) {
              //   chatElement.disable = false;
              //   chatElement.backgroundColor = obj.backgroundColor;
              //   chatElement.height = obj.height;
              // }
              // else if (obj.name === 'questions' && obj.active && !leadInSimpleView) {
              //   questionsElement.disable = false;
              //   questionsElement.backgroundColor = obj.backgroundColor;
              //   questionsElement.height = obj.height;
              // }
              // else if (obj.name === 'defaultvideo') {
              //   let heightValue = undefined;
              //   let widthValue = undefined;

              //   if (!obj.height && obj.width)
              //     heightValue = obj.width * 9 / 16;
              //   else if (obj.height && !obj.width)
              //     widthValue = obj.height * 16 / 9;

              //   if (widthValue)
              //     obj.width = widthValue + 'vw';

              //   if (heightValue)
              //     obj.height = heightValue + 'vh';
              // }
            });
          });
        }
        else {
          layoutSettings = undefined;
        }
      }
    }
    return { layoutSettings: layoutSettings, defaultMessage: defaultMessage, chatElement: chatElement, questionsElement: questionsElement, attendeeCameraElement: hasCamera };
  }

  toggleMuteAll(mute: boolean, showHandOnMute: boolean = true, attendeeMap: any, selectedUser: any, session: OT.Session, changeDetectorRef: ChangeDetectorRef) {
    attendeeMap.forEach((attendee: object, id: string) => {
      if (id != selectedUser['_id']) {
        if (showHandOnMute)
          attendee['requestToShareVideo'] = false;
        attendee['requestToSpeak'] = mute;
        attendeeMap.set(id, attendee);
      }
    });
    this.userCapabilitiesMap = attendeeMap;
    this.sendSignal('disableSpeak', mute + '', session);
    this.autoToggleMuteAll(attendeeMap, selectedUser);
    this.detectChanges(changeDetectorRef);

  }

  private detectChanges(changeDetectorRef) {
    if (!changeDetectorRef['destroyed']) {
      changeDetectorRef.detectChanges();
    }
  }

  autoToggleMuteAll(attendeeMap: any, selectedUser: any) {
    let findIfAnyOneMuted = false;
    attendeeMap.forEach((value: object, key: string) => {
      if (key != selectedUser['_id'] && value['requestToSpeak']) {
        findIfAnyOneMuted = true;
      }
    });
    if (findIfAnyOneMuted) {
      this.muteAll = true;
    }
    else {
      this.muteAll = false;
    }
  }

  disableAttendeeFromSpeaking(muteObj: object, attendeeMap: any, selectedUser: any, session: OT.Session, changeDetectorRef: ChangeDetectorRef, presenterId: string) {
    let userId = muteObj['attendeeId'];
    let mute = muteObj['mute'];
    let currentAttendee = attendeeMap.get(userId);

    this.sendSignal('disableSpeak', mute + '', session, currentAttendee['connection']).then(() => {
      currentAttendee['requestToShareVideo'] = false;
      if (!mute)
        currentAttendee['requestToSpeak'] = false;
      attendeeMap.set(userId, currentAttendee);
      this.userCapabilitiesMap = attendeeMap;

      if (!mute && presenterId && presenterId != selectedUser['_id'])
        this.permitUserPresentation({ attendeeId: userId, bool: false }, attendeeMap, session);
      this.autoToggleMuteAll(attendeeMap, selectedUser);
      this.detectChanges(changeDetectorRef);
    });
  }

  permitUserPresentation(attendee: object, attendeeMap: any, session: OT.Session) {
    let attendeeId = attendee['attendeeId'];
    let bool = attendee['bool'];
    let currentMap = attendeeMap.get(attendeeId);
    if (!bool) {
      currentMap['presenting'] = false;
      currentMap['requestToPresent'] = false;
      attendeeMap.set(attendeeId, currentMap);
      this.userCapabilitiesMap = attendeeMap;

      this.sendSignal('requestStopPresenting', '1', session, currentMap['connection']);
    }
  }

  permitUserSpeak(muteObj: object, attendeeMap: any, session: OT.Session, allowOnlyOneToSpeak: boolean, selectedUser: any, changeDetectorRef: ChangeDetectorRef) {
    let userId = muteObj['studentId'];
    let mute = muteObj['mute'];
    let currentAttendee = attendeeMap.get(userId);
    //console.log('sending signal', currentAttendee)
    if (!mute) {
      this.sendSignal('requestShareVideoResponse', '0', session, currentAttendee['connection'])
    }
    else if (allowOnlyOneToSpeak)
      this.toggleMuteAll(false, true, attendeeMap, selectedUser, session, changeDetectorRef);

    this.sendSignal('disableSpeak', mute + '', session, currentAttendee['connection']).then(() => {
      currentAttendee['requestToSpeak'] = mute;
      currentAttendee['requestToShareVideo'] = false;
      attendeeMap.set(userId, currentAttendee);
      this.userCapabilitiesMap = attendeeMap;

      this.autoToggleMuteAll(attendeeMap, selectedUser);
      this.detectChanges(changeDetectorRef);
    });
  }

  attendeeMuted(muteObj: object, attendeeMap: any, selectedUser: any, session: OT.Session, allowOnlyOneToSpeak: boolean, changeDetectorRef: ChangeDetectorRef) {
    let userId = muteObj['attendeeId'];
    let mute = muteObj['mute'];
    let currentAttendee = attendeeMap.get(userId);

    if (mute && allowOnlyOneToSpeak)
      this.toggleMuteAll(false, true, attendeeMap, selectedUser, session, changeDetectorRef);

    this.sendSignal('muteAttendee', mute + '', session, currentAttendee['connection']).then(() => {
      // currentAttendee['audioOnOff'] = mute;
      if (mute)
        currentAttendee['requestToShareVideo'] = false;
      currentAttendee['requestToSpeak'] = mute
      attendeeMap.set(userId, currentAttendee);
      this.userCapabilitiesMap = attendeeMap;

      this.autoToggleMuteAll(attendeeMap, selectedUser);
      this.detectChanges(changeDetectorRef);
    });
  }

  respondToPresentationRequest(attendee: object, attendeeMap: any, session: OT.Session, selectedUser: any, presenterId: string, allowOnlyOneToSpeak: boolean, changeDetectorRef: ChangeDetectorRef) {
    let attendeeId = attendee['attendeeId'];
    let bool = attendee['bool'];
    let currentAttendee = attendeeMap.get(attendeeId);
    if (bool) {

      if (allowOnlyOneToSpeak)
        this.toggleMuteAll(false, false, attendeeMap, selectedUser, session, changeDetectorRef);

      if (presenterId && presenterId != selectedUser['_id']) {
        this.permitUserPresentation({ attendeeId: presenterId, bool: false }, attendeeMap, session);
      }
      this.sendSignal('requestPresenterRoleResponse', '1', session, currentAttendee['connection']);

      currentAttendee['requestToPresent'] = false;
      // currentAttendee['audioOnOff'] = true;
      // currentAttendee['sharingVideo'] = true;
      // currentAttendee['presenting'] = true;
      currentAttendee['requestToShareVideo'] = false;
      currentAttendee['requestToSpeak'] = true;

      this.disableAttendeeFromSpeaking({ attendeeId: attendeeId, mute: true }, attendeeMap, selectedUser, session, changeDetectorRef, presenterId);
      //console.log('in afterclosed', currentAttendee)
    }
    else {
      this.sendSignal('requestPresenterRoleResponse', '0', session, currentAttendee['connection']);
      currentAttendee['requestToPresent'] = false;
      currentAttendee['presenting'] = false;
      currentAttendee['requestToSpeak'] = false;
    }
    attendeeMap.set(attendeeId, currentAttendee);
    this.userCapabilitiesMap = attendeeMap;
    this.detectChanges(changeDetectorRef);
  }

  requestPresenterRoleSignal(event: any, attendeeMap: any, changeDetectorRef: ChangeDetectorRef) {
    let data = JSON.parse(event['from']['data']);
    if (event['data'] == '1') {
      if (attendeeMap.has(data['userId'])) {
        let currentAttendee = attendeeMap.get(data['userId']);
        currentAttendee['requestToPresent'] = true;
        currentAttendee['requestToShareVideo'] = false;
        attendeeMap.set(data['userId'], currentAttendee);
      }
    }
    else {
      if (attendeeMap.has(data['userId'])) {
        let currentAttendee = attendeeMap.get(data['userId']);
        currentAttendee['requestToPresent'] = false;
        attendeeMap.set(data['userId'], currentAttendee);
      }
    }
    this.userCapabilitiesMap = attendeeMap;
    this.detectChanges(changeDetectorRef);
  }

  requestPermissionToShareVideoSignal(event: any, attendeeMap: any, changeDetectorRef: ChangeDetectorRef) {
    let data = JSON.parse(event['from']['data']);
    if (event['data'] == '1') {
      if (attendeeMap.has(data['userId'])) {
        let currentAttendee = attendeeMap.get(data['userId']);
        currentAttendee['requestToShareVideo'] = true;
        // currentAttendee['requestToSpeak'] = false;
        currentAttendee['requestToPresent'] = false;
        attendeeMap.set(data['userId'], currentAttendee);
      }
    }
    else {
      if (attendeeMap.has(data['userId'])) {
        let currentAttendee = attendeeMap.get(data['userId']);
        currentAttendee['requestToShareVideo'] = false;
        // currentAttendee['requestToSpeak'] = false;
        attendeeMap.set(data['userId'], currentAttendee);
      }
    }
    this.userCapabilitiesMap = attendeeMap;
    this.detectChanges(changeDetectorRef);
  }

  pushedTileItem(data, session: OT.Session, leads: any, guests: any): any {
    if (data && session && session['isConnected']()) {
      let alertSetting = {};
      alertSetting['overlayClickToClose'] = false;
      alertSetting['showCancelButton'] = true;
      alertSetting['confirmText'] = 'Push to attendees only';

      if (leads.length > 0 || guests.length > 0) {
        alertSetting['declineText'] = 'Push to';
        if (leads.length > 0)
          alertSetting['declineText'] += ' ' + this.translate.instant('lead') + ' /';
        if (guests.length > 0)
          alertSetting['declineText'] += ' ' + this.translate.instant('guest') + ' /';
        alertSetting['declineText'] += ' ' + this.translate.instant('attendees');
      }

      const dialogRef = this.layoutService.alertActionElement('', '', alertSetting, '350px');
      return dialogRef;
    }
    return undefined;
  }

  getUserLocationDetails(data: any): any {
    // debugger;
    // let addresses = data.address_components;
    let userLocation: any = { nm1: undefined, cc1: undefined, nm2: undefined, cc2: undefined, cc: undefined, c: undefined };
    data.forEach(element => {
      element.address_components.forEach(address => {
        let elementTypes = address.types;
        if (elementTypes.findIndex(i => i == 'administrative_area_level_1') != -1) {
          userLocation.nm1 = address.long_name;
          userLocation.cc1 = element.geometry.location;
        }
        else if (elementTypes.findIndex(i => i == 'administrative_area_level_2') != -1) {
          userLocation.nm2 = address.long_name;
          userLocation.cc2 = element.geometry.location;
        }
        else if (elementTypes.findIndex(i => i == 'country') != -1) {
          userLocation.c = { nm: address.long_name, pi: element.place_id };
          // userLocation.c = { nm: address.long_name, b: element.geometry.bounds, pi: element.place_id };
        }
      });
    });

    return userLocation;
  }

  sendSignalToBreakoutAttendees(message: string = 'startBreakout', groups: any, session: OT.Session) {
    groups.forEach(element => {
      element.attendees.forEach(attendee => {
        if (attendee.connection && attendee.online) {
          this.sendSignal(message, element.id, session, attendee.connection);
        }
      });
      // let findGroup = groups.find(i => i.attendees.find(j => j.id === element.id));
      // if (findGroup && element.connection)
      //   this.sendSignal(message, findGroup.id, session, element.connection);
    });
  }

  // sendBreakoutSignalThroughServer(breakoutIds: [], message: string, value: string) {
  //   this.requestService.sendSignal({ breakoutIds: breakoutIds, payload: JSON.stringify({ type: message, data: value }) }, (data, error) => {
  //     if (error)
  //       console.log(error);
  //   });
  // }

  sendSignalThroughServer(sessionIds: [], message: string, value: string) {
    this.requestService.sendSignal({ sessionIds: sessionIds, payload: JSON.stringify({ type: message, data: value }) }, (data, error) => {
      if (error)
        console.log(error);
    });
    // this.requestService.sendSignal({ breakoutIds: breakoutIds, payload: JSON.stringify({ type: message, data: value }) }, (data, error) => {
    //   if (error)
    //     console.log(error);
    // });
  }

  getHeight(h, w, ratioSize) {
    if (ratioSize) {
      return ((w * ratioSize.h) / ratioSize.w) + 'vw';
    } else {
      return h + 'vw';
    }
  }
}