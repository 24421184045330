<div [ngStyle]="{'z-index': '1001', 'left': _leftPosition ? _leftPosition + 'px':'', 'display': _hide ? 'none' : 'initial'}"
    class="global-chat-draggable" cdkDrag #globalChatDraggable [cdkDragDisabled]="false"
    (cdkDragStarted)="dragged(true)" [@showDialog]="animationState" cdkDragBoundary=".bodyBoundary">
    <mat-accordion>
        <mat-expansion-panel class="grey-out" [expanded]="true" [hideToggle]="true" [disabled]="true">
            <mat-expansion-panel-header class="panel-header" style="padding: 0 10px 0 10px" collapsedHeight="40px"
                expandedHeight="40px">
                <mat-panel-title class="toolbar-label">
                    <div class="clamp">
                        <mat-icon>
                            chat</mat-icon>
                        <mat-icon>
                            voice_chat
                        </mat-icon>
                    </div>
                    <span>{{'Chat and Video with Attendees' | translate}}</span>
                </mat-panel-title>
                <button *ngIf="true" mat-icon-button class="drag-handle" cdkDragHandle style="margin: 0 5px 0 0">
                    <mat-icon class="toolbar-label">open_with</mat-icon>
                </button>
                <button *ngIf="true" mat-icon-button title="{{'Close' | translate}}" (click)="hideDialog()">
                    <mat-icon class="toolbar-label">close</mat-icon>
                </button>
            </mat-expansion-panel-header>
            <div fxFlex fxLayout="row" *ngIf="selectedUser">
                <div fxFlex="30" class="user-list">
                    <div style="margin: 5px 10px 0 10px">
                        <mat-form-field style="width: 100%;">
                            <mat-label>Search...</mat-label>
                            <input matInput [(ngModel)]="filterByText" (ngModelChange)="filterList()">
                        </mat-form-field>
                    </div>
                    <mat-nav-list>
                        <mat-list-item
                            [ngStyle]="{'background-color': !opentokService.chatPrivatelyWith? 'rgba(0,0,0,.12)':'', 'font-weight': !opentokService.chatPrivatelyWith? 'bold' : ''}"
                            (click)="switchToPublicChat()">
                            {{'@All' | translate}}
                        </mat-list-item>
                        <mat-divider></mat-divider>
                        <!-- <ng-container *ngIf="!isShowAllAttendees"> -->
                        <!-- <ng-container *ngFor="let chatter of activeChats | keyvalue">
                            <mat-list-item *ngIf="!chatter.value.hide"
                                [ngStyle]="{'background-color': opentokService.userFlags[chatter.key]?.selected? 'rgba(0,0,0,.12)':''}"
                                (click)="chatWithAttendee({_id: chatter.key })">
                                <p mat-line
                                    [ngStyle]="{'font-weight': opentokService.userFlags[chatter.key]?.selected ? 'bold': '', 'color': opentokService.userFlags[chatter.key]?.unread && !opentokService.userFlags[chatter.key]?.selected ? 'orange': ''}">
                                    {{chatter.value.name}}
                                </p>
                                <button mat-icon-button *ngIf="opentokService.userFlags[chatter.key]?.selected">
                                    <mat-icon (click)="hideChat(chatter.key)" class="close-chat">close</mat-icon>
                                </button>
                            </mat-list-item>
                            <mat-divider></mat-divider>
                        </ng-container> -->
                        <!-- </ng-container> -->
                        <!-- <ng-container *ngIf="isShowAllAttendees"> -->
                        <div mat-subheader style="font-size:11pt; font-weight: bold;"
                            *ngIf="_filteredActiveList.length > 0">
                            {{'Active chats' | translate}}</div>
                        <ng-container *ngFor="let chatter of _filteredActiveList | keyvalue; let idx = index">
                            <mat-list-item
                                *ngIf="chatter.value.user.userId != selectedUser['_id'] && chatter.value.flags?.showTextChat"
                                [ngStyle]=" {'background-color':
                            chatter.value.flags?.selected? 'rgba(0,0,0,.12)' :''}"
                                (click)="selectAttendee({id: chatter.value.user.userId })">
                                <p mat-line
                                    [ngStyle]="{'font-weight': chatter.value.flags?.selected ? 'bold': '', 'color': chatter.value.flags?.unread && !chatter.value.flags?.selected ? 'orange': chatter.value.flags?.dnd ? 'red': ''}">
                                    {{chatter.value.user.name}}
                                </p>
                                <!-- videoCallRequested == 0 => caller side: did not call yet -->
                                <button mat-icon-button
                                    *ngIf="chatter.value.flags.videoCallRequested == 0 && chatter.value.flags?.selected && !chatter.value.flags?.dnd && chatter.value.flags?.showVideoChat && !opentokService.onGoingCall"
                                    (click)="inviteForVideoChat(chatter.value.user)">
                                    <mat-icon class="video-chat">call</mat-icon>
                                </button>
                                <!-- videoCallRequested == 2 => caller side: call accepted and ongoing -->
                                <button mat-icon-button
                                    *ngIf="chatter.value.flags.videoCallRequested == 2 && opentokService.onGoingCall"
                                    (click)="hangup(chatter.value.user)">
                                    <mat-icon class="video-chat" style="color: red;">call</mat-icon>
                                </button>
                                <!-- videoCallRequested == 1 => caller side: ringing -->
                                <button
                                    *ngIf="chatter.value.flags.videoCallRequested == 1 && !opentokService.onGoingCall"
                                    mat-icon-button title="{{'Cancel' | translate}}">
                                    <mat-icon style="color: red;" (click)="inviteForVideoChat(chatter.value.user)">call
                                    </mat-icon>
                                </button>
                                <!-- videoCallRequested == 3 => recepient side: incoming call and ringing this is to accept call -->
                                <button
                                    *ngIf="chatter.value.flags.videoCallRequested == 3 && !opentokService.onGoingCall"
                                    mat-icon-button title="{{'Answer' | translate}}">
                                    <mat-icon style="color: limegreen;" (click)="answerCall(chatter.value.user)">call
                                    </mat-icon>
                                </button>
                                <!-- videoCallRequested == 3 => recepient side: incoming call and ringing this is to reject call -->
                                <button
                                    *ngIf="chatter.value.flags.videoCallRequested == 3 && !opentokService.onGoingCall"
                                    mat-icon-button title="{{'Decline' | translate}}" style="margin-left:20px">
                                    <mat-icon style="color: red;" (click)="declineCall(chatter.value.user)">call
                                    </mat-icon>
                                </button>
                                <button mat-icon-button>
                                    <mat-icon (click)="hideChat(chatter.value.user.userId)" class="close-chat">close
                                    </mat-icon>
                                </button>
                            </mat-list-item>
                            <mat-divider></mat-divider>
                        </ng-container>

                        <mat-divider style="border-top-width:2px !important;"></mat-divider>
                        <div mat-subheader style="font-size:11pt; font-weight: bold;" *ngIf="_filteredList.length">
                            {{'Attendees' | translate}}</div>
                        <ng-container *ngFor="let chatter of _filteredList | keyvalue; let idx = index">
                            <mat-list-item
                                *ngIf="chatter.value.user.userId != selectedUser['_id'] && chatter.value.flags?.showTextChat"
                                [ngStyle]=" {'background-color':
                            chatter.value.flags?.selected? 'rgba(0,0,0,.12)' :''}"
                                (click)="selectAttendee({id: chatter.value.user.userId })">
                                <p mat-line
                                    [ngStyle]="{'font-weight': chatter.value.flags?.selected ? 'bold': '', 'color': chatter.value.flags?.unread && !chatter.value.flags?.selected ? 'orange': chatter.value.flags?.dnd ? 'red': ''}">
                                    {{chatter.value.user.name}}
                                </p>
                                <!-- <button mat-icon-button *ngIf="opentokService.userFlags[chatter.key]?.selected">
                                    <mat-icon (click)="hideChat(chatter.key)" class="close-chat">close</mat-icon>
                                </button> -->
                                <!-- <button mat-icon-button
                                *ngIf="(chatter.value.flags.videoCallRequested == 0 || chatter.value.flags.videoCallRequested == 2) && !chatter.value.flags?.dnd && chatter.value.flags?.showVideoChat && chatter.value.flags?.selected"
                                (click)="inviteForVideoChat(chatter.value.user)">
                                <mat-icon class="video-chat"
                                    [ngStyle]="{ 'color': chatter.value.flags.videoCallRequested == 2 ? 'lime': ''}">
                                    call
                                </mat-icon>
                            </button>
                            <button *ngIf="chatter.value.flags.videoCallRequested == 1" mat-icon-button
                                title="{{'Cancel' | translate}}">
                                <mat-icon style="color: red;" (click)="inviteForVideoChat(chatter.value.user)">call
                                </mat-icon>
                            </button>
                            <button *ngIf="chatter.value.flags.videoCallRequested == 3" mat-icon-button
                                title="{{'Answer' | translate}}">
                                <mat-icon style="color: green;" (click)="answerCall(chatter.value.user)">call</mat-icon>
                            </button>
                            <button *ngIf="chatter.value.flags.videoCallRequested == 3" mat-icon-button
                                title="{{'Decline' | translate}}" style="margin-left:20px">
                                <mat-icon style="color: red;" (click)="declineCall(chatter.value.user)">call
                                </mat-icon>
                            </button> -->
                            </mat-list-item>
                            <!-- <mat-list-item
                            *ngIf="usersJoined?.length == 1 && chatter.value.user.userId == selectedUser['_id']">
                            <p mat-line>{{'No one is online' | translate}}</p>
                        </mat-list-item> -->
                            <mat-divider></mat-divider>
                        </ng-container>
                        <!-- <mat-list-item *ngIf="_filteredList.length == 0">
                        {{'No one is online' | translate}}
                    </mat-list-item> -->
                        <!-- </ng-container> -->
                    </mat-nav-list>
                </div>
                <!-- <div fxFlex="30" [hidden]="!loading" fxLayoutAlign="center center">
                <mat-progress-spinner [diameter]="40" mode="indeterminate">
                </mat-progress-spinner>
            </div> -->
                <div fxFlex="30" [hidden]="!opentokService.onGoingCall" class="countdown-container">
                    <div fxLayoutAlign="center center">
                        <mat-progress-spinner [hidden]="!loading" [diameter]="40" mode="indeterminate">
                        </mat-progress-spinner>
                    </div>
                    <div fxLayout="column" fxLayoutGap="10px" [hidden]="loading">
                        <div [hidden]="!counter">
                            <div class="countdown" [innerHtml]="counter | countDown">
                            </div>
                            <div class="aspect-container">
                                <div fxFlex #inviteeCamera class="aspect-container-element"
                                    style="background-color: black;"></div>
                            </div>
                            <div fxFlex>
                                <app-camera-play #cameraOfInviter_component (permitCamera)="invokeShareCamera($event)"
                                    [session]="userSession" [disableIcon]="false"
                                    (permitAudio)="invokeShareAudio($event)" (permitMedia)="invokeShareMedia($event)"
                                    [mediaFlags]="mediaFlags" [iconSize]="'2vw'" [labelSize]="'0.7vw'"
                                    [firstTime]="false">
                                </app-camera-play>
                            </div>
                        </div>
                    </div>
                </div>
                <div fxFlex>
                    <!-- <div fxFlex *ngIf="opentokService.chatPrivatelyWith" class="switch-public"
                    (click)="switchToPublicChat()">
                    <button mat-button>{{'Back to public chat' | translate}}</button>
                </div> -->
                    <div fxFlex fxLayout="row" class="chat-container" *ngIf="!opentokService.chatPrivatelyWith">
                        <mat-form-field class="chat-input">
                            <input matInput #message placeholder="{{'Type here...' | translate}}"
                                (keydown.enter)="sendMessage()">
                        </mat-form-field>
                        <button mat-stroked-button (click)="sendMessage()">{{'Send' | translate}}</button>
                    </div>
                    <div fxFlex fxLayout="row" class="chat-container" *ngIf="opentokService.chatPrivatelyWith">
                        <mat-form-field class="chat-input">
                            <input matInput #message placeholder="Chat @{{chattingWithName}}"
                                (keydown.enter)="sendPrivateMessage()">
                        </mat-form-field>
                        <button mat-stroked-button
                            [disabled]="!opentokService.userFlags[opentokService.chatPrivatelyWith]?.joined || opentokService.userFlags[opentokService.chatPrivatelyWith]?.dnd || !opentokService.userFlags[opentokService.chatPrivatelyWith]?.showTextChat"
                            (click)="sendPrivateMessage()">{{'Send' | translate}}</button>
                    </div>
                    <div fxFlex #chat class="chat-messages-container">
                        <div style="max-height: 50vh; height: '50vh'; overflow-y:auto">
                            <ng-container class="chat-messages"
                                *ngFor="let chatter of opentokService.chatMessages | keyvalue">
                                <div data-id="{{chatter.key}}"
                                    *ngIf="(!opentokService.chatPrivatelyWith && chatter.key == 'public') || opentokService.userFlags[chatter.key]?.selected">
                                    <div *ngFor="let chat of chatter.value" class="mat-body-strong message">
                                        <ng-container *ngIf="!chat.missedCall">
                                            <span class="name" [style.color]="chat.color">{{chat.sender}}:&nbsp;</span>
                                            <span class="mat-small">{{chat.message}}</span>
                                        </ng-container>
                                        <ng-container *ngIf="chat.missedCall">
                                            <span class="mat-small"><i>{{chat.message}}</i></span>
                                        </ng-container>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</div>