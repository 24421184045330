import { AfterContentChecked, ChangeDetectorRef, EventEmitter, Renderer2, ViewEncapsulation } from "@angular/core";
import { Component, ElementRef, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { Subscription } from "rxjs";
import { TokBoxService } from "../../services";
import { ChartService } from "../../services/chart.service";

declare var google;

@Component({
    selector: 'app-bubble-worldmap',
    templateUrl: './bubble-worldmap.component.html',
    styleUrls: ['./bubble-worldmap.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class BubbleWorldMapComponent implements OnInit, OnDestroy {

    // private worldMap;
    // private worldMapWidth;
    // private worldMapHeight;
    private subscriptions: Subscription[] = [];
    private pointsOnMap: any = {
        // chicago: {
        //     center: {
        //         lat: 41.878,
        //         lng: -87.629
        //     },
        //     location: {
        //         country: 'US',
        //         long_name: 'US'
        //     },
        //     population: 50000
        // },
        // DC: {
        //     center: {
        //         lat: 40.878,
        //         lng: -86.629
        //     },
        //     location: {
        //         country: 'US',
        //         long_name: 'US'
        //     },
        //     population: 3000
        // },
        // DC1: {
        //     center: {
        //         lat: 38.878,
        //         lng: -88.629
        //     },
        //     location: {
        //         country: 'US',
        //         long_name: 'US'
        //     },
        //     population: 200000
        // },
        // DC2: {
        //     center: {
        //         lat: 38.378,
        //         lng: -88.229
        //     },
        //     location: {
        //         country: 'US',
        //         long_name: 'US'
        //     },
        //     population: 250
        // },
    };
    _map: any = undefined;

    @ViewChild('map') map: ElementRef;

    @Input()
    set initCircles(circles: boolean) {
        if (circles) {
            if (!this._map)
                this.initGoogleMap();
            this.drawCircles();
        }
    }

    constructor(private chartService: ChartService, private renderer: Renderer2) { }

    ngOnInit() {
        // this.loadMap();
        // this.initGoogleMap();

        this.subscriptions.push(this.chartService.plotThisPoint.subscribe((value) => {
            // console.log('this.pointsOnMap', this.pointsOnMap)
            if (value && value.location.nm1) {
                if (value.joined) {
                    if (this.pointsOnMap.hasOwnProperty(value.location.nm1)) {
                        this.pointsOnMap[value.location.nm1].population += 1;
                    }
                    else {
                        this.pointsOnMap[value.location.nm1] = {
                            center: value.location.cc1, population: 1, location: { country: value.location.c, long_name: value.location.nm1 }
                        };
                    }
                }
                else {
                    if (this.pointsOnMap.hasOwnProperty(value.location.nm1)) {
                        this.pointsOnMap[value.location.nm1].population -= 1;
                    }
                    if (this.pointsOnMap.hasOwnProperty(value.location.nm1) && this.pointsOnMap[value.location.nm1].population <= 0) {
                        delete this.pointsOnMap[value.location.nm1];
                    }
                }

                // if (!this._map)
                //     this.initGoogleMap();

                this.drawCircles();

                // if (Object.keys(this.usersOnMap).length == 1) {
                //     // this._map.addListenerOnce(this._map, 'idle', () => {
                //     //     let place = this.usersOnMap[Object.keys(this.usersOnMap)[0]];
                //     //     // console.log('1', this.usersOnMap[Object.keys(this.usersOnMap)[0]].location.country.bounds.southwest)
                //     //     // console.log('2', this.usersOnMap[Object.keys(this.usersOnMap)[0]].location.country.bounds.northeast)
                //     //     let bounds = new google.maps.LatLngBounds();
                //     //     bounds.extend(place.location.country.bounds.southwest);
                //     //     bounds.extend(place.location.country.bounds.northeast);
                //     //     this._map.fitBounds(bounds);
                //     //     this._map.setCenter(place.center);
                //     //     this._map.setZoom(14);
                //     // });


                //     // this._map.setZoom(4);
                //     // this._map.setCenter(this.usersOnMap[Object.keys(this.usersOnMap)[0]].center);
                // }
                // console.log('this.usersOnMap', this.usersOnMap)
            }
        }));
    }

    ngOnDestroy() {
        this.subscriptions.forEach(el => el.unsubscribe());
    }

    initGoogleMap() {
        // const citymap = {
        //     chicago: {
        //         center: {
        //             lat: 41.878,
        //             lng: -87.629
        //         },
        //         population: 2714856
        //     },
        //     newyork: {
        //         center: {
        //             lat: 40.714,
        //             lng: -74.005
        //         },
        //         population: 8405837
        //     },
        //     losangeles: {
        //         center: {
        //             lat: 34.052,
        //             lng: -118.243
        //         },
        //         population: 3857799
        //     },
        //     vancouver: {
        //         center: {
        //             lat: 49.25,
        //             lng: -123.1
        //         },
        //         population: 603502
        //     }
        // };

        // Create the map.
        this._map = new google.maps.Map(this.map.nativeElement, {
            zoom: 2.5,
            center: new google.maps.LatLng(26.5116743, -10.4478529),
            // scaleControl: false,
            mapTypeId: "terrain",
            mapTypeControl: false,
            streetViewControl: false
        }); // Construct the circle for each value in citymap.
        // Note: We scale the area of the circle based on the population.
    }

    drawCircles() {
        if (this._map) {
            let map = this._map;

            // let icon = {
            //     url: "assets/vertical/conference/images/top-logo.png", // url
            //     scaledSize: new google.maps.Size(10, 10), // scaled size
            //     origin: new google.maps.Point(0, 0), // origin
            //     anchor: new google.maps.Point(0, 0) // anchor
            // };

            class Popup extends google.maps.OverlayView {
                constructor(position, content) {
                    super();
                    this.position = position;
                    content.classList.add("popup-bubble"); // This zero-height div is positioned at the bottom of the bubble.

                    const bubbleAnchor = document.createElement("div");
                    bubbleAnchor.classList.add("popup-bubble-anchor");
                    bubbleAnchor.appendChild(content); // This zero-height div is positioned at the bottom of the tip.

                    this.containerDiv = document.createElement("div");
                    this.containerDiv.classList.add("popup-container");
                    this.containerDiv.appendChild(bubbleAnchor); // Optionally stop clicks, etc., from bubbling up to the map.

                    Popup.preventMapHitsAndGesturesFrom(this.containerDiv);
                }
                /** Called when the popup is added to the map. */

                onAdd() {
                    this.getPanes().floatPane.appendChild(this.containerDiv);
                }
                /** Called when the popup is removed from the map. */

                onRemove() {
                    if (this.containerDiv.parentElement) {
                        this.containerDiv.parentElement.removeChild(this.containerDiv);
                    }
                }
                /** Called each frame when the popup needs to draw itself. */

                draw() {
                    const divPosition = this.getProjection().fromLatLngToDivPixel(
                        this.position
                    ); // Hide the popup when it is far out of view.

                    const display =
                        Math.abs(divPosition.x) < 4000 && Math.abs(divPosition.y) < 4000
                            ? "block"
                            : "none";

                    if (display === "block") {
                        this.containerDiv.style.left = divPosition.x + "px";
                        this.containerDiv.style.top = divPosition.y + "px";
                    }

                    if (this.containerDiv.style.display !== display) {
                        this.containerDiv.style.display = display;
                    }
                }
            }

            for (const city in this.pointsOnMap) {
                // Add the circle for this city to the map.
                // debugger;
                const cityCircle = new google.maps.Circle({
                    strokeColor: "#FF0000",
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillColor: "#FF0000",
                    fillOpacity: 0.35,
                    map,
                    center: this.pointsOnMap[city].center,
                    // radius: this.usersOnMap[city].population * 10000
                    // radius: Math.sqrt(this.usersOnMap[city].population) * 10000
                    // radius: (Math.log(this.usersOnMap[city].population) + 1) * 10000
                    radius: Math.pow(this.pointsOnMap[city].population, 0.2368) * 8541
                });

                let textDiv = document.createElement('div');
                this.renderer.setStyle(textDiv, 'font-weight', 'bold');
                this.renderer.setStyle(textDiv, 'color', 'red');
                this.renderer.setStyle(textDiv, 'font-size', '10pt');
                textDiv.innerHTML = this.pointsOnMap[city].population.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

                let popup = new Popup(
                    new google.maps.LatLng(this.pointsOnMap[city].center.lat, this.pointsOnMap[city].center.lng),
                    textDiv
                );
                // console.log('here', textDiv, this.usersOnMap[city].center)
                popup.setMap(map);
            }
        }
    }
}