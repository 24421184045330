import { Component, OnInit, ChangeDetectorRef, OnDestroy, Input, Output, EventEmitter, ViewChild, ElementRef, OnChanges, AfterViewChecked, Renderer2 } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { TokBoxService } from 'src/app/shared';

@Component({
    selector: 'app-camera-play',
    templateUrl: './camera-play.component.html',
    styleUrls: ['./camera-play.component.scss']
})

export class SpeakerCameraComponent implements OnInit, OnDestroy {

    private subscriptions: Subscription[] = [];
    changeDetectorRef: ChangeDetectorRef;
    // currentAttendee: any = undefined;
    // firstTime: boolean = true;
    audioOn: boolean = false;
    cameraOn: boolean = false;
    hovered: boolean = false;

    // @Input() attendeeMap: Map<string, object>;
    // @Input() selectedUser: any;
    @Input() disableIcon: boolean = false;
    @Input() session: OT.Session;
    @Input() iconSize: string = '5em';
    // @Input() toggleIconSize: string = '3em';
    @Input() labelSize: string = '1em';
    @Input() firstTime: boolean = true;
    // @Input() cameraPublisher: OT.Publisher;
    @Input() disableIcons: boolean = false;
    @Input() id: string = undefined;
    @Input() prefixId: string = undefined;
    @Input() showAudioVideoLabels: boolean = true;
    @Input() name: string = undefined;
    @Input()
    set stopMediaFlag(stopMediaFlag: any) {
        if (stopMediaFlag)
            this.stopMedia();
    }
    @Input()
    set mediaFlags(mediaFlags: any) {
        if (mediaFlags) {
            if (mediaFlags.hasOwnProperty('audio'))
                this.audioOn = mediaFlags.audio;
            if (mediaFlags.hasOwnProperty('camera'))
                this.cameraOn = mediaFlags.camera;
            this.firstTime = false;
        }
    }

    @Output('permitCamera') permitCamera = new EventEmitter<any>();
    @Output('permitAudio') permitAudio = new EventEmitter<any>();
    @Output('permitMedia') permitMedia = new EventEmitter<any>();

    @ViewChild('publisherCameraDiv') publisherCameraDiv: ElementRef;
    @ViewChild('camera') cameraDiv: ElementRef;

    constructor(
        private opentokService: TokBoxService,
        private ref: ChangeDetectorRef
    ) {
        this.changeDetectorRef = ref;
    }

    ngOnInit() {
        // this.subscriptions.push(this.opentokService.userCapabilitiesMap.subscribe(value => {
        //     if (value != undefined) {
        //         this.attendeeMap = value;
        //         // this.currentAttendee = this.attendeeMap.get(this.selectedUser['_id']);
        //     }
        // }));
        // this.subscriptions.push(this.opentokService.controlThisStream.subscribe((value) => {
        //     if (value) {
        //         debugger;
        //         let data = JSON.parse(value.connection.data);
        //         if (data['stellarRole'] === 'guest') {
        //             let guest = {};
        //             if (!value['destroyed']) {
        //                 guest['camera'] = value.hasVideo;
        //                 guest['audio'] = value.hasAudio;
        //             }
        //             else {
        //                 guest['camera'] = false;
        //                 guest['audio'] = false;
        //             }
        //             guest['busyChanging'] = false;
        //             // this.joinedGuests.set(data['userId'], guest);
        //             this.cameraOn = guest['camera'];
        //             this.audioOn = guest['audio'];
        //         }
        //         else if (data['stellarRole'] === 'lead') {
        //             debugger;
        //             if (!value['destroyed']) {
        //                 this.leadMediaFlags.camera = value.hasVideo;
        //                 this.leadMediaFlags.audio = value.hasAudio;
        //             }
        //             else {
        //                 this.leadMediaFlags.camera = false;
        //                 this.leadMediaFlags.audio = false;
        //             }
        //             this.cameraOn = this.leadMediaFlags['camera'];
        //             this.audioOn = this.leadMediaFlags['audio'];
        //         }

        //         this.detectChanges();
        //     }
        // }));
    }

    ngOnDestroy() {
        this.subscriptions.forEach(el => el.unsubscribe());
    }

    toggleCamera(bool: boolean) {
        if (!this.disableIcon) {
            if (this.firstTime) {
                this.audioOn = bool;
                this.cameraOn = bool;
                this.permitMedia.emit({ bool: bool, id: this.id });
                this.firstTime = false;
            }
            else {
                this.cameraOn = bool;
                this.permitCamera.emit({ bool: bool, id: this.id });
            }
        }
    }

    toggleAudio(bool: boolean) {
        if (!this.disableIcon) {
            this.firstTime = false;
            this.audioOn = bool;
            this.permitAudio.emit({ bool: bool, id: this.id });
        }
    }

    detectChanges() {
        if (!this.changeDetectorRef['destroyed']) {
            this.changeDetectorRef.detectChanges();
        }
    }

    toggleCameraActions(bool: boolean) {
        this.hovered = bool;
        this.detectChanges();
    }

    stopMedia() {
        this.toggleAudio(false);
        this.toggleCamera(false);
    }
}
