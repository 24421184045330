<div class="mb-20 screenCapture" fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px">
    <div fxFlex>
      <div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px">
          <div fxFlex>
            <span *ngIf="errorMessage">{{errorMessage}}</span>
            <video [hidden]="!withPreview" #video class="video" controls autoplay playsinline ></video>
          </div>
      </div>
      <div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px">
          <div fxFlex>
            <button mat-raised-button color="danger" *ngIf="withVideo || withAudio" class="btn btn-primary" (click)="triggerCaptureScreenWithUserMedia()" [disabled]="disabled"> Record </button>
            <button mat-raised-button color="danger" *ngIf="!(withVideo || withAudio)" class="btn btn-primary" (click)="triggerCaptureScreen()" [disabled]="disabled"> Record </button>
            <button mat-raised-button class="btn btn-primary"(click) ="triggerStopCaptureScreen()" [disabled]="!disabled"> Stop</button>
            <button *ngIf="canDownload" [disabled]="!hasDownload || disabled" mat-raised-button class="btn btn-primary" (click)="download()"> Download</button>
          </div>
      </div>
    </div>
</div>
