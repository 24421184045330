<div *ngIf="mapAreaSelected">
  <div class="imageArea" *ngIf="mapAreaSelected.area && mapAreaSelected.area.length > 0">
    <img style="width: 100%;" src="{{mapAreaSelected.pictureLink}}" class="circle z-depth-2 responsive-img activator" usemap="#image-map" (load)='imageLoaded()'>
    <map name="image-map">
      <ng-container *ngFor="let itm of mapAreaSelected.area">
        <area *ngIf="itm.type !== 'link'" target="{{itm.target}}" alt="{{itm.title}}" title="{{itm.title}}" href="" (click)="openMapLink($event, itm.type, itm.link)" coords="{{itm.coords}}" shape="{{itm.shape}}">
        <area *ngIf="itm.type === 'link'" target="{{itm.target}}" alt="{{itm.title}}" title="{{itm.title}}" href="{{itm.link}}" coords="{{itm.coords}}" shape="{{itm.shape}}">
      </ng-container>
    </map>
  </div>
  <div class="imageArea" *ngIf="oldMaps.includes(mapAreaSelected.key) && (!mapAreaSelected.area || (mapAreaSelected.area && mapAreaSelected.area.length == 0))">
    <!-- height: auto;max-height: 90vh; -->
    <img style="width: 100%;" src="assets/maparea/{{mapAreaSelected.key}}.jpg" class="circle z-depth-2 responsive-img activator" usemap="#image-map" (load)='imageLoaded()'>
    <map name="image-map" *ngIf="mapAreaSelected.key === 'map1'">
        <area target="_blank" alt="Speaker Sessions" title="Speaker Sessions" href="" (click)="openSelfLink($event, '/agenda')" coords="202,128,341,131,343,194,316,198,311,213,341,211,336,236,335,279,333,321,331,359,330,432,212,427,205,365,202,297,193,239,193,211,237,209,240,194,207,194" shape="poly">
        <area target="_blank" alt="Auditorium" title="Auditorium" href="" (click)="viewData($event, '5ecf74fac2c11036b95f9860')" coords="660,387,1212,384,1210,492,1102,515,1105,538,1170,541,1172,576,1102,583,1100,683,1100,724,793,726,795,696,768,689,760,585,707,580,707,536,755,540,753,510,660,493" shape="poly">
        <area target="_blank" alt="Help Desk" title="Help Desk" href="" (click)="viewData($event, '5f213354f78399279f320ae5')" coords="556,648,640,648,640,693,765,689,786,704,780,784,703,817,572,825,552,812,556,716,529,704,556,698,557,673" shape="poly">
        <area target="_blank" alt="Lounge" title="Lounge" href="" (click)="viewData($event, '5eec62d49191bc5686e5f03d')"  coords="383,483,534,485,534,512,532,691,522,722,468,722,414,721,394,719,383,676,376,639,371,576,366,528,363,477" shape="poly">
        <area target="_blank" alt="Store" title="Store" href="" (click)="openSelfLink($event, '/resources')" coords="1336,242,1512,244,1515,274,1539,355,1306,360,1329,277" shape="poly">
        <area target="_blank" alt="About GSAPP" title="About GSAPP" href="https://gsapp.rutgers.edu/" coords="361,242,547,247,547,282,565,354,348,354,365,281" shape="poly">
        <area target="_blank" alt="Exhibit Hall" title="Exhibit Hall" href="" (click)="openSelfLink($event, '/sponsors')"  coords="1331,482,1515,480,1517,516,1333,520" shape="poly">
        <area target="_blank" alt="Exhibit Hall" title="Exhibit Hall" href="" (click)="openSelfLink($event, '/sponsors')"  coords="1544,478,1582,468,1711,470,1720,485,1725,507,1725,581,1723,671,1706,671,1700,683,1685,684,1672,698,1660,709,1653,721,1645,737,1645,749,1655,757,1672,754,1678,771,1673,787,1660,792,1595,795,1597,774,1597,732,1594,669,1594,623,1597,568,1595,526,1592,483" shape="poly">
        <area target="_blank" alt="Agenda" title="Agenda" href="" (click)="openSelfLink($event, '/agenda')"  coords="1138,600,1295,601,1296,834,1273,837,1273,844,1288,845,1301,860,1140,860,1147,847,1162,847,1157,837,1137,832,1135,769,1137,702" shape="poly">
        <area target="_blank" alt="MHT" title="MHT" href="" (click)="viewData($event, '5f1be16978159a0abdc87ef0')"  coords="1522,130,1663,133,1663,193,1632,194,1630,208,1560,206,1550,194,1525,193" shape="poly">
        <area target="_blank" alt="Instructions" title="Instructions" href="" (click)="openIframe($event, '5f1bdc7978159a0abdc87ee3')" coords="84,516,311,523,316,566,315,719,318,832,316,847,330,858,331,868,301,867,82,880,67,875,71,862,86,859,89,749" shape="poly">
        <area target="_blank" alt="Exhibit Hall" title="Exhibit Hall" href="" (click)="openSelfLink($event, '/sponsors')"  coords="1263,526,1594,525,1592,595,1594,661,1592,719,1434,716,1404,716,1383,711,1389,693,1374,676,1361,679,1353,693,1351,714,1339,714,1301,714,1298,679,1300,639,1300,603,1288,593,1276,593,1260,595,1258,548" shape="poly">
    </map>
    <map name="image-map" *ngIf="mapAreaSelected.key === 'mathclassmap1'">
      <area target="_blank" alt="Online Math Games" title="Online Math Games" href="https://www.education.com/games/math/" coords="1253,882,1531,1005" shape="rect">
      <area target="_blank" alt="Math Resources" title="Math Resources" href="http://www.studentguide.org/a-complete-list-of-online-math-resources/" coords="611,883,886,1004" shape="rect">
      <area target="_blank" alt="Drop Your Homework Here" title="Drop Your Homework Here" href="https://1drv.ms/u/s!AvUh0SADHG9amcFp2H1H8mpwaNNrtA?e=fmTooo" coords="935,883,1208,1004" shape="rect">
      <area target="_blank" alt="Math Help" title="Math Help" href="https://www.freemathhelp.com/" coords="1574,883,1853,1009" shape="rect">
    </map>
    <map name="image-map" *ngIf="mapAreaSelected.key === 'scienceclassmap1'">
      <area target="_blank" alt="Drop Homework In Here" title="Drop Homework In Here" href="https://1drv.ms/u/s!AvUh0SADHG9amcFp2H1H8mpwaNNrtA?e=HLQ49Q" coords="155,826,462,995" shape="rect">
      <area target="_blank" alt="Book Resources" title="Book Resources" href="https://www.scholastic.com/teachers/articles/teaching-content/science-books-and-resources/" coords="589,827,897,994" shape="rect">
      <area target="_blank" alt="Class Resources" title="Class Resources" href="https://educationonline.ku.edu/articles/online-learning-resources-for-science-classes" coords="1024,831,1325,996" shape="rect">
      <area target="_blank" alt="Need Help? Click Here." title="Need Help? Click Here." href="https://www.chegg.com/homework-help/science" coords="1436,828,1740,998" shape="rect">
    </map>
    <map name="image-map" *ngIf="mapAreaSelected.key === 'schoolmap1'">
      <area target="" alt="Register" title="Register" href="" (click)="openIframe($event, '5ee0f79d5cdf60397bb66e9d')" coords="619,32,923,132" shape="rect">
      <area target="" alt="Tuition Assistance" title="Tuition Assistance" href="" (click)="openIframe($event, '5ee0f7b45cdf60397bb66e9f')" coords="962,31,1264,134" shape="rect">
      <area target="_blank" alt="My Classes" title="My Classes" href="" (click)="openSelfLink($event, '/rooms/directory')" coords="1301,31,1600,134" shape="rect">
      <area target="_blank" alt="School Website" title="School Website" href="https://www.stanford.edu/" coords="1642,34,1944,131" shape="rect">
    </map>
    <map name="image-map" *ngIf="mapAreaSelected.key === 'exhibitentry1'">
      <area target="_blank" alt="Exhibitor 1" title="Exhibitor 1" href="" (click)="viewData($event, '5f08a82a78d9829624156f7f')" coords="561,189,1300,473" shape="rect">
      <area target="_blank" alt="Exhibitor 2" title="Exhibitor 2" href="" (click)="viewData($event, '5f0dcfd6ee224672b74f8e4d')" coords="160,540,848,779" shape="rect">
      <area target="_blank" alt="Exhibitor 3" title="Exhibitor 3" href="" (click)="viewData($event, '5f0dcfe6ee224672b74f8e4e')" coords="1074,536,1765,781" shape="rect">
    </map>
    <map name="image-map" *ngIf="mapAreaSelected.key === 'lounge1'">
      <area target="_blank" alt="Networking" title="Networking" href="" (click)="openStellarComm($event)" coords="1228,226,1587,320" shape="rect">
      <area target="_blank" alt="Help Desk" title="Help Desk" href="" (click)="openIframe($event, '5ea27f8a4a68992c72caeb06')" coords="1228,349,1587,436" shape="rect">
      <area target="_blank" alt="Attendee Directory" title="Attendee Directory" href="" (click)="openSelfLink($event, '/book')" coords="1226,467,1589,556" shape="rect">
      <area target="_blank" alt="Trauma" title="Trauma" href="https://us02web.zoom.us/j/86300484328" coords="610,188,610,590,193,590,197,184" shape="poly">
    </map>
    <map name="image-map" *ngIf="mapAreaSelected.key === 'stellar-church-lobby'">
      <area target="_blank" alt="Main Service" title="Main Service" (click)="viewData($event, '5f48962c51437b2d234718bb')" href="" coords="280,398,154,109" shape="rect">
       <area target="_blank" alt="Youth Service" title="Youth Service" (click)="viewData($event, '5f47ff6651437b2d234718b9')" coords="4,91,140,398" shape="rect">
       <area target="_blank" alt="Sunday School" title="Sunday School" (click)="viewData($event, '5f4896562653602d291764c1')" href="" coords="288,118,437,394" shape="rect">
       <area target="_blank" alt="Prayer Room" title="Prayer Room" (click)="viewData($event, '5f48967451437b2d234718bc')" coords="544,115,718,398" shape="rect">
       <area target="_blank" alt="Resource Library" title="Resource Library" href="" (click)="openSelfLink($event, '/resources')" coords="724,81,907,398" shape="rect">
       <area target="_blank" alt="Children's Service" title="Children's Service" (click)="viewData($event, '5f4896932653602d291764c2')" href="" coords="943,82,1129,398" shape="rect">
       <area target="_blank" alt="Welcome and Help" title="Welcome and Help" href="" (click)="openIframe($event, '5f48d2fc51437b2d234718ce')" coords="1135,42,1298,458" shape="rect">
    </map>
    <map name="image-map" *ngIf="mapAreaSelected.key === 'main-service-map'">

    </map>
    <map name="image-map" *ngIf="mapAreaSelected.key === 'youth-service-map'">

    </map>
    <map name="image-map" *ngIf="mapAreaSelected.key === 'sunday-school-map'">
      <area target="_blank" alt="Senior's Small Group" title="Senior's Small Group" href="" (click)="viewData($event, '5f49c1376dfed10ea47fff28')" coords="642,394,937,649" shape="rect">
      <area target="_blank" alt="Women's Small Group" title="Women's Small Group" href="" (click)="viewData($event, '5f49c1376dfed10ea47fff28')" coords="441,232,603,413" shape="rect">
      <area target="_blank" alt="Men's Small Group" title="Men's Small Group" href="" (click)="viewData($event, '5f49c1376dfed10ea47fff28')" coords="760,214,896,371" shape="rect">
      <area target="_blank" alt="Welcome-Help" title="Welcome-Help" href="" (click)="viewData($event, '5f49c1376dfed10ea47fff28')" coords="79,170,336,464" shape="rect">
    </map>
    <map name="image-map" *ngIf="mapAreaSelected.key === 'prayer-room-map'">

    </map>
    <map name="image-map" *ngIf="mapAreaSelected.key === 'children-service-map'">

    </map>
    <map name="image-map" *ngIf="mapAreaSelected.key === 'main-map-demo-conf'">
      <area target="_blank" alt="Auditorium" title="Auditorium" href="" (click)="viewData($event, '5f51108727e8052d66f68351')" coords="515,359,768,498" shape="rect">
      <area target="_blank" alt="Lounge" title="Lounge" href="" (click)="viewData($event, '5f5110d8d117b162097dea43')" coords="132,294,364,328" shape="rect">
      <area target="_blank" alt="Speaker Sessions" title="Speaker Sessions" href="" (click)="openSelfLink($event, '/agenda')" coords="273,340,442,363" shape="rect">
      <area target="_blank" alt="About" title="About" href="" (click)="openIframe($event, '5f5111110ab8f62d6c615f19')" coords="353,366,485,385" shape="rect">
      <area target="_blank" alt="Exhibit Hall" title="Exhibit Hall" href="" (click)="openSelfLink($event, '/sponsors')" coords="916,291,1148,328" shape="rect">
      <area target="_blank" alt="Store" title="Store" href="" (click)="openSelfLink($event, '/resources')" coords="840,338,1008,364" shape="rect">
      <area target="_blank" alt="Product" title="Product" href="" (click)="viewData($event, '5f51123d0ab8f62d6c615f1b')" coords="795,366,926,384" shape="rect">
      <area target="_blank" alt="Help Desk" title="Help Desk" href="" (click)="viewData($event, '5f5111d127e8052d66f68353')" coords="812,488,870,471,1014,470,1013,570,933,563,814,554" shape="poly">
    </map>
  </div>
</div>
