<div class="mobile-page">
    <div class="content">
        <div class="mobile-container" fxFlex>
            <div class="text-center" style="text-align: center;margin-bottom: 5px;">
                <!-- <span class="app-name">
                  STELLAR
                </span> -->
                <img src="assets/vertical/{{orgType}}/images/main-logo.png"
                    class="circle z-depth-2 responsive-img activator" style="width: 23vw;margin-bottom: 5vh;">
            </div>
            <div fxFlex fxlayout="row" fxlayout.lt-md="column">
                <div fxFlex style="text-align: center">Stellar is available on Google PlayStore and Apple AppStore</div>
                <div fxFlex style="text-align: center">
                    <img src="../../assets/files/google-play-badge.png" width="200px" />
                    <img style="padding: 11px;" src="../../assets/files/badge-example-preferred.png" />
                </div>
            </div>
        </div>
    </div>
</div>