<form #personForm="ngForm" *ngIf="data" class="customDialog">
	<div class="col-xl-12" *ngIf="data">
		<h1 mat-dialog-title>{{data.title}}</h1>
		<div mat-dialog-content >
			<div *ngIf="data.modalSetting">
	      <mat-card *ngIf="data.modalSetting.fields">
					<!-- <mat-card-header>
						<mat-card-title>{{data.type}}</mat-card-title>
					</mat-card-header> -->
					<mat-card-content>
		        <div class="form-group">
		          <div *ngFor="let col of data.modalSetting.fields" class="fieldItem">
		            <div *ngIf="col.type !== 'action' && (col.editable || !col.generated) && col.creatable">
		              <!-- <label class="control-label" *ngIf="col.name">{{col.displayName}}<span *ngIf="col.required || !col.generated"> *</span>: </label> -->
									<span class="fieldInput">
											<mat-form-field *ngIf="(col.visible || (col.admin && isAdmin)) && col.type === 'string'">

										    <input matInput [required]="!col.nullable" name="{{col.name}}" ng-model="col.name" [(ngModel)]="data.data[col.name]" [required]="!col.nullable"  placeholder="{{col.displayName | translate}}"  [errorStateMatcher]="esMatcher">
												<mat-error *ngIf="!col.nullable && (data.data[col.name] === undefined || data.data[col.name] === '')">
													{{col.displayName | translate}} is required.
												</mat-error>
											</mat-form-field>
											<mat-form-field *ngIf="(col.visible || (col.admin && isAdmin)) && col.type === 'number'">
										    <input matInput type="number" [required]="!col.nullable" name="{{col.name}}" ng-model="col.name" [(ngModel)]="data.data[col.name]" [required]="!col.nullable"  placeholder="{{col.displayName | translate}}"  [errorStateMatcher]="esMatcher">
												<mat-error *ngIf="!col.nullable && (data.data[col.name] === undefined || data.data[col.name] === '')">
													{{col.displayName | translate}} is required.
												</mat-error>
											</mat-form-field>
											<mat-form-field *ngIf="(col.visible || (col.admin && isAdmin)) && col.type === 'email'">
										    <input matInput type="email" [required]="!col.nullable" name="{{col.name}}" ng-model="col.name" [(ngModel)]="data.data[col.name]" [required]="!col.nullable"  placeholder="{{col.displayName | translate}}"  [errorStateMatcher]="esMatcher" pattern="^\w+([\.\+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$" [disabled]="data.data._id && col.unique">
												<mat-error *ngIf="!col.nullable && (data.data[col.name] === undefined || data.data[col.name] === '')" >
													{{col.displayName | translate}} is required.
												</mat-error>
											</mat-form-field>
											<mat-form-field *ngIf="(col.visible || (col.admin && isAdmin)) && col.type === 'url'">
										    <input matInput type="text" [required]="!col.nullable" name="{{col.name}}" ng-model="col.name" [(ngModel)]="data.data[col.name]" [required]="!col.nullable"  placeholder="{{col.displayName | translate}}"  [errorStateMatcher]="esMatcher" pattern="(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})" [disabled]="data.data._id && col.unique">
												<mat-error *ngIf="!col.nullable && (data.data[col.name] === undefined || data.data[col.name] === '')" >
													{{col.displayName | translate}} is required.
												</mat-error>
											</mat-form-field>
											<mat-form-field *ngIf="(col.visible || (col.admin && isAdmin)) && col.type === 'phone'">
										    <input matInput type="phone" [required]="!col.nullable" name="{{col.name}}" ng-model="col.name" [(ngModel)]="data.data[col.name]" [required]="!col.nullable"  placeholder="{{col.displayName | translate}}"  [errorStateMatcher]="esMatcher">
												<mat-error *ngIf="!col.nullable && (data.data[col.name] === undefined || data.data[col.name] === '')">
													{{col.displayName | translate}} is required.
												</mat-error>
											</mat-form-field>
											<mat-form-field *ngIf="(col.visible || (col.admin && isAdmin)) && col.type === 'color'">
										    <input matInput type="color" [required]="!col.nullable" name="{{col.name}}" ng-model="col.name" [(ngModel)]="data.data[col.name]" [required]="!col.nullable"  placeholder="{{col.displayName | translate}}"  [errorStateMatcher]="esMatcher">
												<mat-error *ngIf="!col.nullable && (data.data[col.name] === undefined || data.data[col.name] === '')">
													{{col.displayName | translate}} is required.
												</mat-error>
											</mat-form-field>
											<mat-form-field *ngIf="col.type === 'password'">
										    <input matInput type="password" [required]="!col.nullable" name="{{col.name}}" ng-model="col.name" [(ngModel)]="data.data[col.name]" [required]="!col.nullable"  placeholder="{{col.displayName | translate}}"  [errorStateMatcher]="esMatcher">
												<mat-error *ngIf="!col.nullable && (data.data[col.name] === undefined || data.data[col.name] === '')">
													{{col.displayName | translate}} is required.
												</mat-error>
											</mat-form-field>
											<div *ngIf="(col.visible || (col.admin && isAdmin)) && col.type === 'textarea'">
												<mat-form-field>
													<textarea matInput
															placeholder="{{col['displayName'] | translate}}"
															name="{{col['name']}}"
															[required]="!col['nullable']"
															maxlength="1000"
															[(ngModel)]="data.data[col.name]"
															ng-model="col['name']"
															cdkTextareaAutosize
															cdkAutosizeMinRows="3"
															cdkAutosizeMaxRows="5"
															[errorStateMatcher]="esMatcher">
													</textarea>
													<mat-error *ngIf="!col.nullable && (data.data[col.name] === undefined || data.data[col.name] === '')">
														{{col['displayName'] | translate}} is required.
													</mat-error>
												</mat-form-field>
											</div>
											<div *ngIf="(col.visible || (col.admin && isAdmin)) && col.type === 'boolean' && col.name !== 'privacyterm'" style="margin-bottom:15px;">
												<mat-checkbox class="example-margin" name="{{col.name}}" [(ngModel)]="data.data[col.name]">{{col.displayName}}</mat-checkbox>
									      <!-- <mat-radio-group [value]="data.data[col.name]" name="{{col.name}}"  (change)="setAttributeDummy(index, 'value', $event.value)">
									        <mat-radio-button class="example-margin" value="false">False</mat-radio-button>
									        <mat-radio-button class="example-margin" value="true">True</mat-radio-button>
									      </mat-radio-group> -->
											</div>
											<div *ngIf="(col.visible || (col.admin && isAdmin)) && col.type === 'tags'">
												<app-custom-tags [parentFormSubmitted]="isSubmitted" [value]="data.data[col.name]" [required]="!col.nullable" [placeholder]="col.displayName" [canAdd]="true" (onSelectReturn)="setAttribute(col.name, $event)" [itemName]="col.name" [canDelete]="true" [dataTypeParent]="data.dataType" [dataTypeParentDisplay]="data.dataType"></app-custom-tags>
											</div>
											<div *ngIf="(col.visible || (col.admin && isAdmin)) && col.type === 'reference'">
												<app-custom-select  *ngIf="data.data[col.name] && col.reference.kind === 'single'" [parentFormSubmitted]="isSubmitted" [value]="data.data[col.name]['_id']" [itemName]="col.name" [required]="!col.nullable" [dataType]="col.reference.to" [apiTarget]="col.reference.api" [placeholder]="col.displayName" (onRoleSelectReturn)="setRoleReferenceAttribute(col.name, $event)" (onSelectReturn)="setReferenceAttribute(col.name, '_id', $event)"></app-custom-select>
												<app-custom-select  *ngIf="data.data[col.name] && col.reference.kind === 'multiple' && col.name === 'resources'" [parentFormSubmitted]="isSubmitted" [value]="data.data[col.name][0]['_id']" [itemName]="col.name" [required]="!col.nullable" [dataType]="col.reference.to" [apiTarget]="col.reference.api" [placeholder]="col.displayName" (onRoleSelectReturn)="setRoleReferenceAttribute(col.name, $event)" (onSelectReturn)="setReferenceAttribute(col.name, '_id', $event)"></app-custom-select>
												<app-custom-multiple-select *ngIf="data.data[col.name] && col.reference.kind === 'multiple' && col.name !== 'resources'" [value]="data.data[col.name]" [itemName]="col.name" [required]="!col.nullable" [dataType]="col.reference.to"  [apiTarget]="col.reference.api" [placeholder]="col.displayName" (onSelectReturn)="setMultipleReferenceAttribute(col.name, $event)"></app-custom-multiple-select>
											</div>
											<div *ngIf="(col.visible || (col.admin && isAdmin)) && col.type === 'maparray'" class="maparray">
												<label class="control-label" *ngIf="col.name">{{col.displayName}}<span *ngIf="!col.nullable"> *</span>:
													<mat-icon (click)="addArrayMapOption(col.name)" style="float: right;" class="newRow" title="Add Area">add_circle_outline</mat-icon>
												</label>
												<div *ngIf="data.data[col.name]" style="clear:both">
													<mat-card class="itemView" *ngFor="let itm of data.data[col.name]; let index=index">
														<div>
																<span style="font-weight:bold">{{index+1}}. {{data.data[col.name][index]['title']}}</span>
																<div style="float:right">
																		<i class="material-icons" (click)="deleteArrayMap(col.name, index)" style="cursor: pointer;">delete_forever</i>
																</div>
														</div>
														<mat-card-content class="itemData" style="clear:both">
															<mat-form-field class="example-full-width">
																<input name="title{{index}}" matInput ng-model="col.name"
																 [(ngModel)]="data.data[col.name][index]['title']" placeholder="Title">
															</mat-form-field>
															<mat-form-field class="example-full-width">
														    <input name="coords{{index}}" matInput ng-model="col.name"
																 [(ngModel)]="data.data[col.name][index]['coords']" placeholder="Coords">
														  </mat-form-field>
															<label class="control-label">Shape</label>
															<mat-select ng-model="col.name" (selectionChange)="setMapArrayShape(index, col.name, $event.value)" [value]="data.data[col.name][index]['shape']" style="margin-bottom: 20px;">
											          <mat-option value="rect" >{{'Rect' | translate}}</mat-option>
											          <mat-option value="poly" >{{'Poly' | translate}}</mat-option>
											          <mat-option value="circle" >{{'Circle' | translate}}</mat-option>
											        </mat-select>
															<label class="control-label">Link To:</label>
															<mat-select ng-model="col.name" (selectionChange)="setMapArrayType(index, col.name, $event.value)" [value]="data.data[col.name][index]['type']">
											          <mat-option value="none" >{{'None' | translate}}</mat-option>
											          <mat-option value="link" >{{'Url' | translate}}</mat-option>
											          <mat-option value="tile" >{{'Tile' | translate}}</mat-option>
											          <mat-option value="room" >{{'Room' | translate}}</mat-option>
											          <mat-option value="page" >{{'Page' | translate}}</mat-option>
											          <mat-option value="stellarcomm" >{{'StellarComm' | translate}}</mat-option>
											        </mat-select>
															<mat-form-field class="example-full-width" *ngIf="data.data[col.name][index]['type'] !== 'none'">
														    <input name="link{{index}}" matInput ng-model="col.name"
																 [(ngModel)]="data.data[col.name][index]['link']">
														    <mat-icon matSuffix *ngIf="data.data[col.name][index]['type'] !== 'link' && data.data[col.name][index]['type'] !== 'page'" style="cursor:pointer" (click)="selectPictureLinkType(index, col.name, data.data[col.name][index]['type'])">mode_edit</mat-icon>
														  </mat-form-field>
															<mat-form-field class="example-full-width" *ngIf="data.data[col.name][index]['type'] === 'link'">
														    <input name="target{{index}}" matInput ng-model="col.name"
																 [(ngModel)]="data.data[col.name][index]['target']" placeholder="Target">
														  </mat-form-field>
														</mat-card-content>
													</mat-card>
												</div>
											</div>
											<div *ngIf="(col.visible || (col.admin && isAdmin)) && col.type === 'picturearray'" class="picturearray" style="display: inline-block;">
												<label class="control-label" *ngIf="col.name">{{col.displayName}}<span *ngIf="!col.nullable"> *</span>:
													<mat-icon (click)="fileselect.click()" class="newRow" title="Add Image">add_circle_outline</mat-icon>
												</label>
												<input [hidden]="true" type="file" name="image" #fileselect (change)="onBrowseFiles(col.name, $event.target)"/>
												<div *ngIf="data.data[col.name]">
													<mat-card class="itemView" *ngFor="let itm of data.data[col.name]; let index=index">
														<mat-card-header>
															<i class="material-icons" (click)="deletePictures(col.name, index)">delete_forever</i>
														</mat-card-header>
														<div class="imageParent">
															<img mat-card-image src="{{itm.url}}">
														</div>
														<mat-card-content class="itemData">
															<label class="control-label">Link To:</label>
															<mat-select ng-model="col.name" (selectionChange)="setPictureArrayType(index, col.name, $event.value)" [value]="data.data[col.name][index]['type']">
											          <mat-option value="none" >{{'None' | translate}}</mat-option>
											          <mat-option value="link" >{{'Url' | translate}}</mat-option>
											          <mat-option value="tile" >{{'Tile' | translate}}</mat-option>
											          <mat-option value="room" >{{'Room' | translate}}</mat-option>
											          <mat-option value="page" >{{'Page' | translate}}</mat-option>
											        </mat-select>
															<mat-form-field class="example-full-width" *ngIf="data.data[col.name][index]['type'] !== 'none'">
														    <input name="link{{index}}" matInput ng-model="col.name"
																 [readonly]="data.data[col.name][index]['type'] !== 'link' && data.data[col.name][index]['type'] !== 'page'" [(ngModel)]="data.data[col.name][index]['link']">
														    <mat-icon matSuffix *ngIf="data.data[col.name][index]['type'] !== 'link' && data.data[col.name][index]['type'] !== 'page'" style="cursor:pointer" (click)="selectPictureLinkType(index, col.name, data.data[col.name][index]['type'])">mode_edit</mat-icon>
														  </mat-form-field>
														</mat-card-content>
													</mat-card>
												</div>
											</div>
											<div *ngIf="(col.visible || (col.admin && isAdmin)) && col.type === 'datetime'">
												<app-custom-datetime [value]="data.data[col.name]" [metadata]="col" (dateChange)="data.data[col.name] = $event"></app-custom-datetime>
											</div>
											<div *ngIf="(col.visible || (col.admin && isAdmin)) && col.type === 'date'">
												<mat-form-field>
					                <input matInput name="{{col.name}}" [required]="!col.nullable" [(ngModel)]="data.data[col.name]" ng-model="col.name" [matDatepicker]="picker" placeholder="{{col.displayName| translate}}" (dateChange)="setDateAttribute(col.name, $event.value)" [errorStateMatcher]="esMatcher">
					                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
					                <mat-datepicker #picker></mat-datepicker>
					                <mat-error *ngIf="!col.nullable && (data.data[col.name] === undefined || data.data[col.name] === '')">
					                  {{col.displayName| translate}} is required.
					                </mat-error>
					              </mat-form-field>
											</div>
											<mat-form-field *ngIf="(col.visible || (col.admin && isAdmin)) && col.type === 'enum'">
								        <input [style.visibility]="'hidden'" matInput name="{{col.name}}" [(ngModel)]="data.data[col.name]" [required]="!col.nullable" [errorStateMatcher]="esMatcher">
								        <mat-label>{{col.displayName | translate}}</mat-label>
								        <mat-select ng-model="col.name" [required]="!col.nullable" (selectionChange)="setAttribute(col.name, $event.value)" [value]="data.data[col.name]">
								          <mat-option value="" >Select {{col.displayName | translate}}</mat-option>
								          <mat-option *ngFor="let itm of col.enum" [value]="itm.value" >{{itm.displayName | translate}}</mat-option>
								        </mat-select>
								        <mat-error *ngIf="!col.nullable && (data.data[col.name] === undefined || data.data[col.name] === '')">
								          {{col.displayName | translate}} is required.
								        </mat-error>
								      </mat-form-field>

									</span>
		            </div>
		          </div>
		        </div>
					</mat-card-content>
	      </mat-card>
	    </div>
		</div>
		<div mat-dialog-actions>
			<!-- <span *ngIf="data.modalSetting && !data.modalSetting.hasOwnProperty('footer')"> -->
				<button color="danger" mat-raised-button (click)="closeModal(undefined)">{{'Close' | translate}}</button>
				<button *ngIf="!data.data._id" mat-raised-button (click)="saveData('create')" [disabled]="loading" >{{'Create' | translate}}</button>
				<button *ngIf="data.data._id" mat-raised-button (click)="saveData('edit')" [disabled]="loading" >{{'Save' | translate}}</button>
		</div>
		<br/>
	</div>
</form>
