export class DefaultSettings {
  public static componentsType: any = {
    desktop: ['camera', 'guest-camera', 'defaultvideo', 'screenshare', 'presenter-camera', 'tile', 'billboard', 'editor'],
    'desktop-offline': ['defaultvideo', 'tile', 'billboard', 'editor'],
    'mobile-offline': ['defaultvideo', 'tile', 'billboard', 'editor'],
    mobile: ['camera', 'guest-camera', 'defaultvideo', 'screenshare', 'presenter-camera', 'chat', 'questions', 'tile', 'billboard', 'editor']
  };
  public static peopleType: any = {
    lead: 'Lead',
    guest: 'Guest',
    attendee: 'Attendee',
    moderator: 'Moderator',
  }
  public static peopleTypeKeys: string[] = Object.keys(DefaultSettings.peopleType);
  public static cameraComponents: string[] = ['camera', 'guest-camera', 'screenshare', 'presenter-camera'];
  public static tileComponents: string[] = ['tile','questionnaire','inquiry','linkDescription'];
  public static defaultSetting = {
    version : 3,
    desktop : {
      columns: [
        {
          components: [
            {
              active: true,
              h: 14,
              name: "presenter-camera",
              viewPublicChat: true,
              w: 25,
              x: 75,
              y: 4,
              z: 1
            },
            {
              active: true,
              enableAllowAllSpeak: true,
              h: 14,
              name: "camera",
              w: 25,
              x: 0,
              y: 4,
              z: 1
            },
            {
              active: true,
              h: 22,
              message: "",
              name: "screenshare",
              w: 40,
              x: 30,
              y: 4,
              z: 1
            }
          ]
        }
      ],
      optionSettings: {
        enableScreenShareButton: false,
        enableFullScreen: false,
        enableCaptureScreen: false,
        enableRaiseHand: false,
        enableChat: false,
        enableAskQuestions: false
      }
    },
    mobile: {
      columns: [
        {
          rowGap: 0,
          components: []
        }
      ],
      optionSettings: {
        enableScreenShareButton: false,
        enableFullScreen: false,
        enableCaptureScreen: false,
        enableRaiseHand: false,
        enableChat: false,
        enableAskQuestions: false
      }
    },
    'desktop-offline' : {
      columns: [
        {
          components: []
        }
      ],
    },
    'mobile-offline' : {
      columns: [
        {
          components: []
        }
      ],
    },
    'desktop-hls': {
      enableFullScreen: false,
      enableChat: false,
      enableAskQuestions: false,
      addScreenShare: false,
      layout: 'bestFit' //bestFit, pip, verticalPresentation, horizontalPresentation
    },
    'mobile-hls': {
      enableFullScreen: false,
      enableChat: false,
      enableAskQuestions: false,
      addScreenShare: false,
      layout: 'bestFit' //bestFit, pip, verticalPresentation, horizontalPresentation
    }
  };
}
