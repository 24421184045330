<div [ngStyle]="{'z-index': _zIndex, 'left': _leftPosition ? _leftPosition + 'px':'', 'display': _hide ? 'none' : 'initial'}"
    cdkDrag [class.draggable]="!disableDrag" #answersDraggable (cdkDragStarted)="dragged(true)"
    [cdkDragDisabled]="disableDrag" [@showDialog]="animationState" cdkDragBoundary=".dragBoundary">
    <mat-accordion>
        <mat-expansion-panel [class.grey-out]="!disableDrag" [expanded]="expanded" [hideToggle]="!disableDrag"
            [disabled]="true">
            <mat-expansion-panel-header class="panel-header" *ngIf="!disableDrag"
                [ngStyle]="{'padding': disableDrag? '0 20px 0 10px' : '0 10px 0 10px'}" collapsedHeight="40px"
                expandedHeight="40px">
                <mat-panel-title class="toolbar-label">
                    <mat-icon class="icon-title">contact_support</mat-icon>
                    <span>{{'Answers' | translate}}</span>
                </mat-panel-title>
                <button mat-icon-button class="drag-handle" cdkDragHandle style="margin: 0 5px 0 0">
                    <mat-icon class="toolbar-label">open_with</mat-icon>
                </button>
                <button mat-icon-button title="{{'Close' | translate}}" (click)="closeDialog()">
                    <mat-icon class="toolbar-label">close</mat-icon>
                </button>
            </mat-expansion-panel-header>
            <div class="mb-20" fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="0.5vw">
                <div fxFlex="30">
                    <app-lecture-tiles-list #lectureTilesList [selectedData]="selectedData"
                        (selectItem)="selectItemCheck($event)" [hasCategories]="true" [pageSize]="5"
                        [sessionData]="sessionData" (pushedItem)="pushedTileItem($event)"
                        (unPushItem)="unPushItem($event)" [disablePush]="!advancedView" [disableAdd]="!advancedView"
                        [darkMode]="!disableDrag">
                    </app-lecture-tiles-list>
                </div>
                <div fxFlex>
                    <mat-tab-group #tabGroup animationDuration="0ms">
                        <mat-tab label="{{'Answers' | translate}}">
                            <ng-template matTabContent>
                                <app-tile-answers-percentage *ngIf="selectedData" [lectureId]="sessionData._id"
                                    [selectedData]="selectedData" [hideToggle]="true" [expanded]="true" [hide]="false"
                                    [disableDrag]="true">
                                </app-tile-answers-percentage>
                            </ng-template>
                        </mat-tab>
                        <mat-tab label="{{'Preview' | translate}}">
                            <ng-template matTabContent>
                                <app-iframe-url [iframeLink]="iframeLink" [iframeParentHeight]="'280px'">
                                </app-iframe-url>
                            </ng-template>
                        </mat-tab>
                    </mat-tab-group>
                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</div>