import { Component, OnInit, Input, ChangeDetectorRef, Output, EventEmitter, Renderer2, ViewChild } from '@angular/core';
import { RequestService, LayoutUtilsService, LoaderService } from '../../../../shared/services';
import { Subscription, interval } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../../environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { urlSafeBase64Encoding } from '../../../../shared/helpers';

import { FileModalDialogComponent } from '../../../../shared/components/custom-file-upload-dialog/custom-file-upload-dialog.component';
import { ModalDialogComponent } from '../../../../shared/components/custom-dialog/custom-dialog.component';
import { SessionAssignDialogComponent } from '../../../../shared/components/session-assign-dialog/session-assign-dialog.component';
import { CustomSelectDialogComponent } from '../../../../shared/components/custom-select-dialog/custom-select-dialog.component';
import { ModalAdvancedGalleryDialogComponent } from '../../../../shared/components/custom-advanced-gallery-dialog/custom-advanced-gallery-dialog.component';
import { LayoutComponents, DefaultSettings } from '../';
import { TileSelectDialogComponent } from '../../../../shared/components/tile-select-dialog/tile-select-dialog.component';
import { BillboardSelectDialogComponent } from '../../../../shared/components/billboard-select-dialog/billboard-select-dialog.component';
import { ModalTileCreateDialogComponent } from '../../../../shared/components/tile-create-dialog/tile-create-dialog.component';
import { ScreenLayoutComponent } from '../screen-layout/screen-layout.component';

@Component({
  selector: 'app-layout-dynamic-data',
  templateUrl: './layout-dynamic-data.component.html',
  styleUrls: ['./layout-dynamic-data.component.scss']
})
export class LayoutDynamicDataComponent implements OnInit {
  private subscriptions: Subscription[] = [];
  public selectedUser: any;
  public orgType = environment.orgType;
  public errorMessage: string = '';
  public loading: boolean = false;
  public hasFormErrors: boolean = false;
  public columnsLengthType: any = {desktop: 1, 'desktop-offline': 1, mobile: 1};
  public isSubmitted: boolean = true;
  public viewMode: string = 'desktop';
  public showMobile: boolean = false;
  public componentsType: any = DefaultSettings.componentsType;
  public cameraComponents: string[] = DefaultSettings.cameraComponents;
  public tileComponents: string[] = DefaultSettings.tileComponents;
  public componentsName: any = LayoutComponents.componentsName;
  public componentsNameKeys: any = LayoutComponents.componentsNameKeys;
  public peopleType: any = DefaultSettings.peopleType;
  public peopleTypeKeys: any = DefaultSettings.peopleTypeKeys;
  public garbageList: any[] = [];
  public componentsListType: any = {desktop: [], 'desktop-offline': [], mobile: []};
  public defaultSetting = DefaultSettings.defaultSetting;
  public settingObject = undefined;
  public displayingVersion = undefined;
  public metaFieldSetting = undefined;
  public originalTableSetting: any = undefined;
  public guestsTotal: number = 0;
  public openedLeftMenu: string = '';
  public openedRightMenu: string = 'componentSettings';
  public openedRightTemplateMenu: string = '';

  public optionDesktopSettingsStatus: any = {enableScreenShareButton: true, enableFullScreen: true, enableCaptureScreen: true, enableRaiseHand: true, enableChat: true, enableAskQuestions: true};

  public showPageSettings: boolean = false;
  public bgBackgroundLink: string = '';
  public bgBackgroundColor: string = '';

  public selectedColumn = undefined;
  public selectedComponent = undefined;
  public liveXY = {name: '', x: 0, y: 0, c: 0, m: 0, w: 0, h: 0, z: 1, bgcolor: ''};

  public templateList: any[] = [];

  public sessionList = [];

  // @Input() templateSettings: any = undefined;
  @Input() data: any = undefined;
  @Output() closeModalReturn = new EventEmitter<any>();
  @Output() sessionReturn = new EventEmitter<any>();
  @Output() viewModeReturn = new EventEmitter<string>();
  @Output() streamModeReturn = new EventEmitter<string>();
  @Output() showMobileReturn = new EventEmitter<boolean>();

  @ViewChild('desktopOnline') desktopOnline: ScreenLayoutComponent;
  @ViewChild('desktopOffline') desktopOffline: ScreenLayoutComponent;
  constructor(
    private layoutUtilsService: LayoutUtilsService, private requestService: RequestService, private translate: TranslateService, public renderer: Renderer2,
    public dialog: MatDialog, private router: Router, private activatedRoute: ActivatedRoute, private changeDetectorRef: ChangeDetectorRef, private loaderService: LoaderService) {
  }

  ngOnInit() {
    this.subscriptions.push(
      this.requestService.currentUserSubject.subscribe((data) => {
        if (data) {
          // console.log('this.data', this.data);
          this.selectedUser = data;
          this.buildSetting();
          this.getSessions(this.data.roomData);
          if(this.data.roomData){
            this.bgUpdate(this.data.roomData.pictureLink, this.data.roomData.bgcolor);
          }

          // else{
          //   this.renderer.setAttribute(document.body, 'style', "background: url('/assets/images/defaultbgd2.jpg'); background-repeat:no-repeat; background-size:cover; background-attachment: fixed; background-position: center 64px;");
          // }
        }
      })
    );
  }
  ngOnDestroy() {
    this.renderer.removeStyle(document.body, 'background');
    this.renderer.removeStyle(document.body, 'overflow-y');
    this.subscriptions.forEach(el => el.unsubscribe());
  }
  bgUpdate(bgBackgroundLink, bgBackgroundColor){
    this.bgBackgroundColor = bgBackgroundColor;
    this.bgBackgroundLink = bgBackgroundLink;
    let styleBG = "background: ";
    if(bgBackgroundColor !== ''){
      styleBG = styleBG + bgBackgroundColor;
    }else{
      styleBG = styleBG + '#efefef';
    }
    if(bgBackgroundLink !== ''){
      styleBG = styleBG + " url(" + bgBackgroundLink + "); background-repeat:no-repeat; background-size:contain; background-attachment: fixed; background-position: center 55px; overflow-y: hidden;" ;
      this.renderer.setAttribute(document.body, 'style', styleBG );
    }else{
      styleBG = styleBG + ";"
      this.renderer.setAttribute(document.body, 'style', styleBG );
    }
  }
  cancelThru(e) {
    if(e){
      e.preventDefault();
      e.stopPropagation();
    }
  }
  selectComponent(cmp) {
    if(cmp.hasOwnProperty('selectedColumn')){
      this.selectedColumn = cmp.selectedColumn;
    }else{
      this.selectedColumn = undefined;
    }
    if(cmp.hasOwnProperty('selectedComponent')){
      this.selectedComponent = cmp.selectedComponent;
    }else{
      this.selectedComponent = undefined;
    }
    if(cmp.hasOwnProperty('liveXY')){
      this.liveXY = cmp.liveXY;
    }
  }
  selectedComponentChange(option, $event) {
    if((option === 'w' || option === 'h') && $event > 100){
      $event = 10;
    }
    if((option === 'y' || option === 'x') && $event > 99){
      $event = 0;
    }
    if(option === 'z' && $event > 99){
      $event = 99;
    }
    let settingObject = JSON.parse(JSON.stringify(this.settingObject[this.viewMode]));
    settingObject['columns'][this.selectedColumn]['components'][this.selectedComponent][option] = $event;
    this.liveXY[option] = $event;
    this.data.data['settings'][this.viewMode] = JSON.parse(JSON.stringify(settingObject));
    this.cleanSettings();
  }
  validateComponents(cmp, viewMode){
    if(!this.componentsName[cmp.name].fixed){
      let currentSettings = JSON.parse(JSON.stringify(this.settingObject[viewMode]));
      for (let col of currentSettings['columns']) {
        col['components'] = col['components'].filter((comp) => cmp.name === comp.name);
        if(col['components'].length > 0){
          return false;
        }
      }
    }
    return true;
  }
  insertComponent(cmp) {
    if(this.validateComponents(cmp, this.viewMode)){
      if(this.tileComponents.includes(cmp.name) ){
        this.addTile(cmp);
      }else if (cmp.name === 'billboard'){
        this.addBillboard(cmp);
      }else{
        let currentSettings = JSON.parse(JSON.stringify(this.settingObject[this.viewMode]));
        currentSettings['columns'][0]['components'].push(cmp);
        this.data.data['settings'][this.viewMode] = JSON.parse(JSON.stringify(currentSettings));
        this.cleanSettings();
        this.selectChildLastComponent();
        this.openedLeftMenu = '';
        this.openedRightTemplateMenu = '';
      }
    }else{
      this.layoutUtilsService.showNotification(this.translate.instant('Error:') + ' You cannot select more than one ' + this.translate.instant(this.componentsName[cmp.name].displayName), this.translate.instant('Dismiss'));
    }
  }
  selectChildLastComponent(){
    setTimeout(()=>{
      let currentSettings = JSON.parse(JSON.stringify(this.settingObject[this.viewMode]));
      let subIndex = currentSettings['columns'][0]['components'].length - 1;
      if(this.viewMode === 'desktop'){
        this.desktopOnline.selectComponent(undefined, 0 , subIndex);
      }else if (this.viewMode === 'desktop-offline'){
        this.desktopOffline.selectComponent(undefined, 0 , subIndex);
      }
    }, 100);
  }
  updateLayout(returnObject) {
    this.data.data['settings'][this.viewMode] = JSON.parse(JSON.stringify(returnObject.currentSettings));
    this.cleanSettings();
  }
  private buildSetting() {
    if (!this.loading) {
      this.loading = true;
      this.errorMessage = '';
      this.data.modalSetting.fields = [];
      this.requestService.getMetaData(this.data.dataType, undefined, (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification(this.translate.instant('Error:') + error, this.translate.instant('Dismiss'));
        }
        this.loading = false;
        if (data) {
          this.originalTableSetting = data.results;
          this.metaFieldSetting = this.buildMetaSetting(data.results);
          let newFields = data.results.fields;
          let idx = 0;
          if (this.data.modalSetting.hasOwnProperty('customSettings')) {
            for (let fld of newFields) {
              if (this.data.modalSetting.customSettings.hasOwnProperty(fld.name)) {
                newFields[idx]['visible'] = this.data.modalSetting.customSettings[fld.name].visible;
              }
              idx++;
            }
          }
          this.data.modalSetting.fields = newFields;
          if (this.data.data.hasOwnProperty('_id')) {
            this.loadData();
          }
        } else {
          this.layoutUtilsService.showNotification(this.translate.instant('Something is Wrong'), this.translate.instant('Dismiss'));
        }
      });
    }
  }
  selectSession(sessionId){
    if(this.data.data._id !== sessionId){
      this.showPageSettings = false;
      this.data.data = { _id: sessionId };
      this.settingObject = undefined;
      if(sessionId){
        this.loadData();
      }else{
        sessionStorage.removeItem('sessionId');
        this.sessionReturn.emit(undefined);
        this.settingObject = undefined;
      }
    }
  }
  editSessionSetting(sessionId){
    if(this.data.data._id !== sessionId){
      this.showPageSettings = false;
      this.data.data = { _id: sessionId };
      this.loadData();
    }
    this.showPageSettings=true;
  }
  buildMetaSetting(data, parent = undefined) {
    let dataObject = {};
    let tabObject = [];
    for (let col of data.fields) {
      if ((col.editable || !col.generated) && col.type !== 'object' && col.type !== 'table') {
        if (parent) {
          col['inputName'] = parent + col['name'];
        }
        dataObject[col.name] = col;
      } else if (col.type === 'object') {
        dataObject[col.name] = this.buildMetaSetting(col);
        tabObject.push({ name: col.name, displayName: col.displayName, fields: this.buildMetaSetting(col, col.name) });
      } else if (col.type === 'table') {
        dataObject[col.name] = this.buildMetaSetting(col);
      }
    }
    return dataObject;
  }
  loadDefaultSettings() {
    this.data.data['settings'] = JSON.parse(JSON.stringify(this.defaultSetting));
    this.settingObject = undefined;
    setTimeout(()=>{
      this.cleanSettings();
    }, 100);
  }
  resetSettings() {
    if(this.data.data._id){
      let currentSession = JSON.parse(JSON.stringify(this.data.data._id));
      this.showPageSettings = false;
      this.data.data = { _id: currentSession };
      this.settingObject = undefined;
      this.loadData();
    }else{
      this.loadDefaultSettings();
    }
  }
  setOptions(e, id, val) {
    if(e){
      e.preventDefault();
      e.stopPropagation();
    }
    this.settingObject['desktop']['optionSettings'][id]=val;
  }
  setOptionsHLS(e, id, val) {
    if(e){
      e.preventDefault();
      e.stopPropagation();
    }
    if(id === 'addScreenShare'){
      this.updateAddScreenShare(val);
    }else{
      this.settingObject['desktop-hls'][id]=val;
    }
  }
  updateAddScreenShare(val) {
    let currentSettings = JSON.parse(JSON.stringify(this.settingObject['desktop-hls']));
    currentSettings['addScreenShare']=val;
    if (!val && currentSettings['layout'] ==='pip' && this.guestsTotal !== 1)
      currentSettings['layout']='bestFit';

    if (val && currentSettings['layout'] ==='pip' && this.guestsTotal === 1)
      currentSettings['layout']='bestFit';

    this.data.data['settings']['desktop-hls'] = JSON.parse(JSON.stringify(currentSettings));
    this.settingObject['desktop-hls'] = JSON.parse(JSON.stringify(currentSettings));
  }
  loadTemplateSettings() {
    this.templateList = [];
    let filters: any = {'$and': [
      {'organizationId._id': { '$eq': this.requestService.orgId }}
    ]};
    this.requestService.getDataList('layout', {
      fieldKeys: ['_id', 'name', 'bgImage', 'bgcolor'], orderDir: 'desc', orderBy: 'createdAt', filter: filters
    }, (data, error) => {
      if (error) {
        this.layoutUtilsService.showNotification('No ' + this.translate.instant('Template') + ' available', 'Dismiss');
      }
      if (data) {
        this.templateList = data.results;
      }else{
        this.templateList = [];
      }
      this.openedRightTemplateMenu = 'tempalatesList';
    });
  }
  public loadTemplateSetting(dataId) {
    this.settingObject = undefined;
    this.openedRightTemplateMenu = '';
    if (!this.loading) {
      this.loading = true;
      this.loaderService.display(true);
      this.errorMessage = '';
      this.requestService.getSingleData('layout', dataId, (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification(this.translate.instant('Error:') + error, this.translate.instant('Dismiss'));
        }
        this.loaderService.display(false);
        this.loading = false;
        if (data) {
          let templatedata = data.results;
          this.data.data['settings'] = JSON.parse(JSON.stringify(templatedata.settings));
          //We might need to add bgcolor
          // this.bgBackgroundColor = this.data.roomData.bgcolor;
          this.bgUpdate(templatedata.bgImage, templatedata.bgcolor);
          this.cleanSettings();
        }
      });
    }
  }
  public deleteTemplate($event, index, dataId) {
    if($event){
      $event.preventDefault();
      $event.stopPropagation();
    }
    if (!this.loading) {
      const _title: string = this.translate.instant('Template') + ' ' + this.translate.instant('Deletion');
      const _description: string = this.translate.instant('Are you sure you want to permanently delete this') + ' ' + this.translate.instant('Template') + '?';
      const _waitDesciption: string = this.translate.instant('Deleting') + '...';

      const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
      dialogRef.afterClosed().subscribe(res => {
        if (!res) {
          return;
        }
        this.realTemplateDelete(index, dataId);
      });
    }
  }
  public realTemplateDelete(index, id: any) {
    const _deleteMessage = this.translate.instant('Template') + ' ' + this.translate.instant('Deleted Successfully') + '.';
    if (!this.loading) {
      this.loading = true;
      this.errorMessage = '';
      this.requestService.deleteSingleData('layout', id, (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification(this.translate.instant('Error:') + error, this.translate.instant('Dismiss'));
        }
        this.loading = false;
        if (data) {
          this.templateList.splice(index, 1);
        }
      });
    }
  }
  public loadData() {
    if (!this.loading) {
      this.loading = true;
      this.loaderService.display(true);
      this.errorMessage = '';
      this.settingObject = undefined;
      let dataId = this.data.data['_id'];
      // if (this.data.modalSetting.hasOwnProperty('useOrgId')) {
      //   dataId = dataId + '/' + this.requestService.orgId;
      // }
      this.requestService.getSingleData(this.data.dataType, dataId, (data, error) => {
        this.loaderService.display(false);
        if (error) {
          // this.errorMessage = error;
          // this.layoutUtilsService.showNotification(this.translate.instant('Error:') + error, this.translate.instant('Dismiss'));
          this.selectSession(undefined);
        }
        if (data) {
          this.data.data = data.results;
          this.sessionReturn.emit(this.data.data);
          sessionStorage.setItem('sessionId', JSON.stringify(dataId));
          this.streamModeReturn.emit(this.data.data.streamMode);
          if (!this.data.data.settings || (this.data.data.settings && this.data.data.settings === '') || (this.data.data.settings && this.data.data.settings.hasOwnProperty('version') && this.data.data.settings.version !== this.defaultSetting.version)) {
            this.loadDefaultSettings();
            // this.layoutUtilsService.showNotification(this.translate.instant('Error:') + ' Version not compatible, reset to default settings', this.translate.instant('Dismiss'));
          }
          if (this.data.data.users) {
            let guests = this.data.data.users.filter((user) => user.type === 'guest');
            this.guestsTotal = guests.length;
          }
          this.cleanSettings();
        }
        this.loading = false;
      });
    }
  }
  calculateCameraTotal(): number{
    let currentSettings = JSON.parse(JSON.stringify(this.data.data['settings']['desktop']));
    let cameraTotal = 0;
    let enableAllowAllSpeak = false;
    for (let col of currentSettings['columns']) {
        col['components'] = col['components'].filter((cmp) => this.cameraComponents.includes(cmp.name));
        if(this.data.data['streamMode'] === 'rtctohls'){ // attendee camera is not there we exclude it
          col['components'] = col['components'].filter((cmp) => cmp.name !== 'camera');
        }
        if(col['components'].length > 0){
          for (let cmp of col['components']) {
              if(cmp.name === 'camera'){
                if(cmp.enableAllowAllSpeak){
                  enableAllowAllSpeak = cmp.enableAllowAllSpeak;
                }
              }
              cameraTotal = cameraTotal + 1;
          }
        }else{
          return -1;
        }
    }
    // console.log('cameraTotal' ,enableAllowAllSpeak, cameraTotal);
    let specialNumber = 2800;
    if(enableAllowAllSpeak){
      specialNumber = 53;
    }
    let maximumAttendees = specialNumber / cameraTotal;
    return Math.floor(maximumAttendees);
  }
  setStreamMode(value) {
    if(value !== undefined){
      this.data.data['streamMode'] = value;
      this.streamModeReturn.emit(value);
    }
    this.cleanSettings();
  }
  cleanStreamMode() {
    if(this.data.data.streamMode !== ''){
      if(this.data.data.streamMode === 'hls'){
        if(this.viewMode !== 'desktop-offline'){
          this.setViewModeReturn(undefined,'desktop-hls');
        }
        this.data.data.maximumAttendees = 0;
        this.data.data.maximumCameras = 6;
      }else{
        if(this.viewMode !== 'desktop-offline'){
          this.setViewModeReturn(undefined,'desktop');
        }
        if(this.data.data.streamMode === 'rtc'){
          let numberofcameras = this.calculateCameraTotal();
          this.data.data.maximumAttendees = numberofcameras;
          this.data.data.maximumCameras = 0;
        }else if (this.data.data.streamMode === 'meeting'){
          this.data.data.maximumAttendees = 50;
          this.data.data.maximumCameras = 0;
        }else{
          // rtctohls
          let numberofcameras = this.calculateCameraTotal();
          this.data.data.maximumAttendees = numberofcameras;
          this.data.data.maximumCameras = 6;
        }
      }
    }else{
      this.data.data.maximumAttendees = 0;
      this.data.data.maximumCameras = 0;
      this.setViewModeReturn(undefined,'desktop-offline');
    }
  }
  refresh() {
    let currentSettings = JSON.parse(JSON.stringify(this.settingObject));
    this.data.data['settings'] = JSON.parse(JSON.stringify(currentSettings));
    this.cleanSettings();
  }
  public cleanSettings() {
    let currentSettings = JSON.parse(JSON.stringify(this.data.data['settings']));
    this.settingObject = JSON.parse(JSON.stringify(currentSettings));
    if (currentSettings.hasOwnProperty('version') && currentSettings.version === this.defaultSetting.version) {
      this.cleanTypeSettings('desktop');
      this.cleanTypeSettings('desktop-offline');
      this.cleanHLSSettings();
      this.cleanStreamMode();
    }else{
      this.loadDefaultSettings();
      // this.layoutUtilsService.showNotification(this.translate.instant('Error:') + ' Version not compatible, reset to default settings', this.translate.instant('Dismiss'));
    }
    // console.log('settingObject', this.settingObject);
    // console.log('componentsListType', this.componentsListType);
  }
  public cleanTypeSettings(type) {
    let currentSettings = JSON.parse(JSON.stringify(this.data.data['settings'][type]));
    let componentsList = []; // the list to show on left add comonent buttun
    let selectedComponents = [];
    let selectedActiveComponents = [];
    this.columnsLengthType[type] = currentSettings['columns'].length;
    for (let col of currentSettings['columns']) {
      col['components'] = col['components'].filter((cmp) => this.componentsType[type].includes(cmp.name));
      if(this.data.data['streamMode'] === 'rtctohls'){
        col['components'] = col['components'].filter((cmp) => cmp.name !== 'camera');
      }
      for (let cmp of col['components']) {
          selectedComponents.push(cmp.name);
          if(cmp.active){
            selectedActiveComponents.push(cmp.name);
          }
      }
    }
    let componentsNameKeys = this.componentsNameKeys;
    if(this.data.data['streamMode'] === 'rtctohls'){
      componentsNameKeys = componentsNameKeys.filter((cmp) => cmp !== 'camera');
    }

    for (let key of componentsNameKeys) {
      if (this.componentsType[type].includes(key)) {
        if (selectedComponents.indexOf(key) === -1 || this.componentsName[key]['fixed']) {
          componentsList.push(JSON.parse(JSON.stringify(this.componentsName[key]['content'])));
        }
      }
    }
    if(type === 'desktop'){
      if(selectedActiveComponents.indexOf('camera') === -1){
        this.optionDesktopSettingsStatus['enableRaiseHand'] = false;
        currentSettings['enableRaiseHand'] = false;
      }else{
        this.optionDesktopSettingsStatus['enableRaiseHand'] = true;
      }
      if(selectedActiveComponents.indexOf('camera') === -1 || selectedActiveComponents.indexOf('screenshare') === -1){
        this.optionDesktopSettingsStatus['enableScreenShareButton'] = false;
        currentSettings['enableScreenShareButton'] = false;
      }else{
        this.optionDesktopSettingsStatus['enableScreenShareButton'] = true;
      }
    }
    // console.log('componentsList', type, componentsList);
    this.componentsListType[type] = JSON.parse(JSON.stringify(componentsList));
    this.settingObject[type] = JSON.parse(JSON.stringify(currentSettings));
  }
  public cleanHLSSettings() {
    if (!this.data.data['settings'].hasOwnProperty('desktop-hls')) {
      this.data.data['settings']['desktop-hls'] = JSON.parse(JSON.stringify(this.defaultSetting['desktop-hls']));
    }

    let currentSettings = JSON.parse(JSON.stringify(this.data.data['settings']['desktop-hls']));
    this.settingObject['desktop-hls'] = JSON.parse(JSON.stringify(currentSettings));
  }
  public setAttribute(id, val) {
    this.data.data[id] = val;
  }
  public setAttributeBoolean(id, val) {
    this.data.data[id] = JSON.parse(val);
  }
  public setReferenceAttribute(parentId, id, val) {
    this.data.data[parentId][id] = val;
  }
  public setMultipleReferenceAttribute(id, val) {
    this.data.data[id] = val;
  }
  public setRoleReferenceAttribute(parentId, val) {
    this.data.data[parentId] = { _id: val._id, name: val.name };
  }
  public setDateAttribute(id, val) {
    // console.log('setDateAttribute', id, val.toISOString());
    try {
      this.data.data[id] = val.toISOString();
    } catch (e) {
      // error
    }
  }
  private getCleanObject(data) {
    let newObj = { _id: data._id };
    for (let col of this.data.modalSetting.fields) {
      if ((col.editable || !col.generated) && col.type !== 'action') {
        if (col.dataType === 'password') {
          newObj[col.name] = urlSafeBase64Encoding(data[col.name]);
        } else if (col.type === 'reference') {
          if (col.reference.kind === 'multiple') {
            if (data[col.name] && data[col.name].length > 0)
              newObj[col.name] = data[col.name];
          } else {
            if (data[col.name] !== '')
              newObj[col.name] = data[col.name];
          }
        } else {
          newObj[col.name] = data[col.name];
        }
        // if (this.data.modalSetting.hasOwnProperty('customSettings')) {
        //   if (this.data.modalSetting.customSettings.hasOwnProperty(col.name)) {
        //     newObj[col.name] = this.data.modalSetting.customSettings[col.name].value;
        //   }
        // }
      }
    }
    return newObj;
  }
  private validateObject(data) {
    for (let col of this.data.modalSetting.fields) {
      if ((!col.nullable && !col.generated) && col.type !== 'action' && col.visible) {
        if (col.type === 'reference') {
          if (col.reference.kind === 'multiple') {
            // console.log('col.name', col.name, data[col.name] );
            if (data && data[col.name] && data[col.name].length === 0) {
              return false;
            }
          } else {
            if (data && data[col.name] && data[col.name] === '') {
              // console.log('col.name', col.name, data[col.name] );
              return false;
            }
          }
        } else if (col.type === 'tags') {
          if (data && (data[col.name].length === 0 || data[col.name] === undefined)) {
            // console.log('col.name', col.name, data[col.name] );
            return false;
          }
        } else {
          if (data && (data[col.name] === '' || data[col.name] === undefined)) {
            // console.log('col.name', col.name, data[col.name] );
            return false;
          }
        }
      }
    }
    return true;
  }
  public saveData(type) {
    console.log('settingObject', this.settingObject);
    // console.log('this.data.modalSetting', this.data.modalSetting);
    // console.log('getCleanObject', this.getCleanObject(this.data.data));
    if (!this.loading) {
      this.updateRoomImage();
      this.data.data['settings'] = JSON.parse(JSON.stringify(this.settingObject)); // adding the selected form
      if(!this.validateLayout()){
        return;
      }
      this.loading = true;
      let dataToSave = { _id: this.data.data['_id'], name: this.data.data['name'], settings: this.data.data['settings'], maximumAttendees: this.data.data['maximumAttendees'], maximumCameras: this.data.data['maximumCameras'], streamMode: this.data.data['streamMode'] };
      this.errorMessage = '';
      this.requestService.saveData(this.data.dataType, dataToSave, (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification(this.translate.instant('Error:') + error, this.translate.instant('Dismiss'));
        }
        if (data) {
          this.layoutUtilsService.showNotification(this.data.dataTypeTitle + ' ' + this.translate.instant('edited Successfully'), this.translate.instant('Dismiss'));
          this.getSessions(this.data.roomData);
        }
        this.loading = false;
      });
    }
  }
  public validateLayout(){
    //Desktop validate
    let viewMode = 'desktop';
    let currentSettings = JSON.parse(JSON.stringify(this.data.data['settings'][viewMode]));
    if(this.data.data['streamMode'] === 'rtctohls' || this.data.data['streamMode'] === 'rtc'){
      for (let col of currentSettings['columns']) {
        for (let cmp of col['components']) {
          if(!this.componentsName[cmp.name].fixed){
            for (let col of currentSettings['columns']) {
              let componentsList = col['components'].filter((comp) => cmp.name === comp.name);
              if(componentsList.length > 1){
                this.layoutUtilsService.showNotification(this.translate.instant('Error:') + ' You cannot select more than one ' + this.translate.instant(this.componentsName[cmp.name].displayName), this.translate.instant('Dismiss'));
                return false;
              }
            }
          }
        }
        let leads = [];
        if(this.data.data.hasOwnProperty('users')){
          leads = this.data.data.users.filter((user) => user.type === 'lead');
        }
        let componentsList = col['components'].filter((cmp) => cmp.name === 'presenter-camera'); // if we need to apply for inactive add it here in the filter
        if(leads.length > 0){
          if(componentsList.length === 0){
              this.layoutUtilsService.showNotification('You must add a ' + this.translate.instant('lead') + ' Camera', this.translate.instant('Dismiss'));
              return false;
          }
        }
      }
      //Check if we need to add rtc too
      if(this.data.data['streamMode'] === 'rtctohls'){
        let selectedComponents = [];
        for (let col of currentSettings['columns']) {
          let componentsList = col['components'].filter((cmp) => this.cameraComponents.includes(cmp.name));
          for (let cmp of componentsList) {
              selectedComponents.push(cmp.name);
          }
        }
        if(selectedComponents.length > 6){
          this.layoutUtilsService.showNotification('Maximum number of cameras including screenshare is 6', this.translate.instant('Dismiss'));
          return false;
        }
      }
    }
    return true
  }
  public updateRoomImage() {
    if((this.bgBackgroundLink !== undefined && this.bgBackgroundLink !== this.data.roomData.pictureLink) || (this.bgBackgroundColor !== undefined && this.bgBackgroundColor !== this.data.roomData.bgcolor)){
      // console.log('settingObject', this.settingObject);
        let dataToSave = { _id: this.data.roomData['_id'], name: this.data.roomData['name'], pictureLink: this.bgBackgroundLink, bgcolor: this.bgBackgroundColor };
        this.requestService.saveData('room', dataToSave, (data, error) => {
          if (error) {
            this.layoutUtilsService.showNotification(this.translate.instant('Error:') + error, this.translate.instant('Dismiss'));
          }
          if (data) {
            // Room Image updated
          }
        });
    }
  }
  closeModal(data): void {
    this.closeModalReturn.emit(data);
  }
  setShowMobileReturn(e): void {
    if (e && e.screenX > 0) {
      //console.log('setShowMobileReturn', e);
      this.showMobile = !this.showMobile;
      this.showMobileReturn.emit(this.showMobile);
    }
  }
  setViewModeReturn(e, mode: string = 'desktop'): void {
    //if (e && e.screenX > 0) {
      this.viewMode = mode;
      this.viewModeReturn.emit(this.viewMode);
    //}
  }
  public toggleClick(action, target, data) {
    if (target === 'parent') {
      if (this.validateObject(this.data.data)) {
        this.closeModal({ action: action, data: this.data.data, value: data });
      } else {
        this.layoutUtilsService.showNotification(this.translate.instant('Error:') + this.translate.instant('You need to select all mandatory fields'), this.translate.instant('Dismiss'));
      }
    } else {
      //console.log('toggleClick Self', action, target, data);
      if (action === 'close') {
        this.closeModal(undefined);
      }
    }
  }
  clearIt(e) {
    // e.preventDefault();
    // e.stopPropagation();
  }
  /**
  * Get sessions by room
  */
  getSessions(roomData) {
    let roomId = roomData._id;
    let filters = {
      "$and": [{ "room._id": { "$eq": roomId } }]
    };
    // let filters = {
    //   "$and": [{ "room._id": { "$eq": roomId } }, { "opentoall": true }]
    // };
    this.requestService.getDataList('session', {
      fieldKeys: ['_id', 'name'], orderDir: 'asc', orderBy: 'startsAt', filter: filters
    }, (data, error) => {
      if (error) {
        this.layoutUtilsService.showNotification('No ' + this.translate.instant('session') + ' available', 'Dismiss');
      }
      if (data) {
        this.sessionList = data.results;
      }else{
        this.sessionList = [];
      }
    });
  }
  /**
  * Create session popup
  */
  createSession(){
    const dialogRef = this.dialog.open(ModalDialogComponent, {
      width: '800px',
      data: {
        dataType: this.data.dataType,
        dataTypeTitle: this.translate.instant('Session'),
        title: this.translate.instant('Create') + ' ' + this.translate.instant('Session'),
        data: {},
        modalSetting: Object.assign({}, this.data.modalSetting)
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.confirmCreate(result.data);
      }
    });
  }
  public confirmCreate(data) {
    if (!this.loading) {
      this.loading = true;
      //  this.loaderService.display(true);
      this.errorMessage = '';
      this.requestService.saveDataToRoom(this.data.roomData._id, this.data.dataType, data, (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification(this.translate.instant('Error:') + error, this.translate.instant('Dismiss'));
        }
        this.loading = false;
        if (data) {
          this.layoutUtilsService.showNotification(this.translate.instant('Session') + ' ' + this.translate.instant('created Successfully'), this.translate.instant('Dismiss'));
          this.selectSession(data.results._id);
          this.getSessions(this.data.roomData);
        }
      });
    }
  }
  /**
  * Edit session popup
  */
  editSession(sessionId){
    // console.log('this.data.modalSetting', this.data.modalSetting);
    const dialogRef = this.dialog.open(ModalDialogComponent, {
      width: '800px',
      data: {
        dataType: this.data.dataType,
        dataTypeTitle: this.translate.instant('Session'),
        title: this.translate.instant('Edit') + ' ' + this.translate.instant('Session'),
        data: { _id: sessionId },
        modalSetting: Object.assign({}, this.data.modalSetting)
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        //console.log('result', result);
        this.confirmEdit(result.data);
      }
    });
  }
  public confirmEdit(data) {
    if (!this.loading) {
      this.loading = true;
      //  this.loaderService.display(true);
      this.errorMessage = '';
      this.requestService.saveData(this.data.dataType, data, (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification(this.translate.instant('Error:') + error, this.translate.instant('Dismiss'));
        }
        this.loading = false;
        if (data) {
          this.layoutUtilsService.showNotification(this.translate.instant('Session') + ' ' + this.translate.instant('edited Successfully'), this.translate.instant('Dismiss'));
          if(this.data.data._id === data.results._id){
            this.data.data.name = data.results.name;
          }
          this.getSessions(this.data.roomData);
        }
      });
    }
  }
  /**
  * Delete session popup
  */
  deleteSession(sessionId) {
    if (!this.loading) {
      const _title: string = this.translate.instant('Session') + ' ' + this.translate.instant('Deletion');
      const _description: string = this.translate.instant('Are you sure you want to permanently delete this') + ' ' + this.translate.instant('Session') + '?';
      const _waitDesciption: string = this.translate.instant('Deleting') + '...';

      const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
      dialogRef.afterClosed().subscribe(res => {
        if (!res) {
          return;
        }
        this.realDelete(sessionId);
      });
    }
  }
  public realDelete(id: any) {
    const _deleteMessage = this.translate.instant('Session') + ' ' + this.translate.instant('Deleted Successfully') + '.';
    if (!this.loading) {
      this.loading = true;
      this.errorMessage = '';
      this.requestService.deleteSingleData(this.data.dataType, id, (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification(this.translate.instant('Error:') + error, this.translate.instant('Dismiss'));
        }
        this.loading = false;
        if (data) {
          this.layoutUtilsService.showNotification(_deleteMessage, this.translate.instant('Dismiss'));
          this.selectSession(undefined);
          this.getSessions(this.data.roomData);
        }
      });
    }
  }
  /**
  * set Background popup
  */
  setBG () {
    this.openedLeftMenu = '';
    const dialogRef = this.dialog.open(FileModalDialogComponent, {
      width: '800px',
      data: {
        dataType: 'room',
        dataTypeTitle: this.translate.instant('Rooms'),
        title: this.translate.instant('Upload') + ' ' + this.translate.instant('Background'),
        data: { _id: this.data.roomData._id},
        variable: 'pictureLink',
        targetApi: 'image',
        maxHeight: 3000,
        maxWidth: 3000,
        handleBgColor: 'bgcolor',
        targetDeleteApi: 'imagedelete',
        modalSetting: {}
      //  modalSetting: Object.assign({}, this.tableSetting)
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result.hasOwnProperty('bgcolor')){
        this.bgBackgroundColor = result.bgcolor;
        this.bgBackgroundLink = result.pictureLink;
        this.bgUpdate(this.bgBackgroundLink, this.bgBackgroundColor);
      }else{
        this.bgUpdate(result.pictureLink, this.bgBackgroundColor);
      }
    });
  }
  /**
  * Gallery popup
  */
  public galleryLibrary() {
    const dialogRef = this.dialog.open(ModalAdvancedGalleryDialogComponent, {
      width: '100vw',
      disableClose: false,
      autoFocus: false,
      data: {
        handleBgColor: true,
        title: this.translate.instant('Set') + ' ' + this.translate.instant('Background'),
        data: { bgBackgroundColor: this.bgBackgroundColor, bgBackgroundLink: this.bgBackgroundLink},
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.bgBackgroundColor = result.bgBackgroundColor;
        this.bgBackgroundLink = result.bgBackgroundLink;
        this.bgUpdate(this.bgBackgroundLink, this.bgBackgroundColor);
      }
    });
  }
  /**
  * Add tile popup
  */
  addTile(cmp) {
    const dialogRef = this.dialog.open(TileSelectDialogComponent, {
      width: '600px',
      data: {
        title: this.translate.instant('Select') + ' ' + this.translate.instant('Tile'),
        data: {},
        hasAdd: true,
        hideClear: true
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.hasOwnProperty('_id')) {
          let currentSettings = JSON.parse(JSON.stringify(this.settingObject[this.viewMode]));
          cmp['metadata']['id'] = result['_id'];
          cmp['metadata']['name'] = result['title'];
          currentSettings['columns'][0]['components'].push(cmp);
          this.data.data['settings'][this.viewMode] = JSON.parse(JSON.stringify(currentSettings));
          this.cleanSettings();
          this.selectChildLastComponent();
          this.openedLeftMenu = '';
        }else if (result.hasOwnProperty('new')){
          this.createTile(cmp);
        }
      }
    });
  }
  /**
  * Add Billboard popup
  */
  addBillboard(cmp) {
    let currentSettings = JSON.parse(JSON.stringify(this.settingObject));
    const dialogRef = this.dialog.open(BillboardSelectDialogComponent, {
      width: '600px',
      data: {
        title: this.translate.instant('Select') + ' ' + this.translate.instant('Billboard'),
        data: {metadata: {id: '', name: ''}},
        hasAdd: true
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.hasOwnProperty('_id')) {
          let currentSettings = JSON.parse(JSON.stringify(this.settingObject[this.viewMode]));
          cmp['metadata']['id'] = result['_id'];
          if (result.hasOwnProperty('text')) {
            cmp['metadata']['name'] = result['text'];
          } else {
            cmp['metadata']['name'] = result['title'];
          }
          currentSettings['columns'][0]['components'].push(cmp);
          this.data.data['settings'][this.viewMode] = JSON.parse(JSON.stringify(currentSettings));
          this.cleanSettings();
          this.selectChildLastComponent();
          this.openedLeftMenu = '';
        }else if (result.hasOwnProperty('new')){
          this.createBillboard(cmp);
        }
      }
    });
  }

  /**
  * Save Template popup
  */
  saveTemplateSettings() {
    let filterData: any = {'$and': [
      {'organizationId._id': { '$eq': this.requestService.orgId }}
    ]};
    const dialogRef = this.dialog.open(ModalDialogComponent, {
      width: '800px',
      data: {
        dataType: 'layout',
        dataTypeTitle: this.translate.instant('Template'),
        title: this.translate.instant('Create') + ' ' + this.translate.instant('Template'),
        data: {},
        modalSetting: this.getTemplateSetting({}, this.getTemplateCustomFilter())
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.confirmLayoutCreate(result.data);
      }
    });
  }
  private getTemplateSetting(data, filters) {
    let tableSetting = data;
    tableSetting['target'] = 'parent';
    if (filters)
      tableSetting['filters'] = filters;
    tableSetting['customSettings'] = {
      organizationId: {
        visible: false,
        value: { _id: this.requestService.orgId, name: '' }
      },
      bgImage: {
        visible: false,
        value: this.bgBackgroundLink
      },
      bgcolor: {
        visible: false,
        value: this.bgBackgroundColor
      },
      settings: {
        visible: false,
        value: JSON.parse(JSON.stringify(this.settingObject))
      }
    };
    return tableSetting;
  }
  private getTemplateCustomFilter() {
      let filters = {};
      filters['$and'] = [{ "organizationId._id": { "$eq": this.requestService.orgId } }];
      return filters;
  }
  public confirmLayoutCreate(data) {
    if (!this.loading) {
      this.loading = true;
      //  this.loaderService.display(true);
      this.errorMessage = '';
      this.requestService.saveData('layout', data, (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification(this.translate.instant('Error:') + error, this.translate.instant('Dismiss'));
        }
        this.loading = false;
        if (data) {
          this.layoutUtilsService.showNotification(this.translate.instant('Template') + ' ' + this.translate.instant('created Successfully'), this.translate.instant('Dismiss'));
          this.loadTemplateSettings();
        }
      });
    }
  }
  /**
  * Create Billboard popup
  */
  createBillboard(cmp) {
    let filterData: any = {'$and': [
      {'organizationId._id': { '$eq': this.requestService.orgId }}
    ]};
    const dialogRef = this.dialog.open(ModalDialogComponent, {
      width: '100vw',
      data: {
        dataType: 'banner',
        dataTypeTitle: this.translate.instant('Billboard'),
        title: this.translate.instant('Create') + ' ' + this.translate.instant('Billboard'),
        data: {},
        modalSetting: this.getBillboardSetting({}, this.getBillboardCustomFilter())
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.confirmBillboardCreate(cmp, result.data);
      }
    });
  }
  private getBillboardSetting(data, filters) {
    let tableSetting = data;
    tableSetting['target'] = 'parent';
    if (filters)
      tableSetting['filters'] = filters;
    tableSetting['customSettings'] = {
      organizationId: {
        visible: false,
        value: { _id: this.requestService.orgId, name: '' }
      }
    };
    return tableSetting;
  }
  private getBillboardCustomFilter() {
      let filters = {};
      filters['$and'] = [{ "organizationId._id": { "$eq": this.requestService.orgId } }];
      return filters;
  }
  public confirmBillboardCreate(cmp, data) {
    if (!this.loading) {
      this.loading = true;
      //  this.loaderService.display(true);
      this.errorMessage = '';
      this.requestService.saveData('banner', data, (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification(this.translate.instant('Error:') + error, this.translate.instant('Dismiss'));
        }
        this.loading = false;
        if (data) {
          let currentSettings = JSON.parse(JSON.stringify(this.settingObject[this.viewMode]));
          cmp['metadata']['id'] = data['_id'];
          if (data.hasOwnProperty('text')) {
            cmp['metadata']['name'] = data['text'];
          } else {
            cmp['metadata']['name'] = data['title'];
          }
          currentSettings['columns'][0]['components'].push(cmp);
          this.data.data['settings'][this.viewMode] = JSON.parse(JSON.stringify(currentSettings));
          this.cleanSettings();
          this.selectChildLastComponent();
          this.openedLeftMenu = '';
          this.layoutUtilsService.showNotification(this.translate.instant('Billboard') + ' ' + this.translate.instant('created Successfully'), this.translate.instant('Dismiss'));
        }
      });
    }
  }
  /**
  * Create Tile popup
  */
  createTile(cmp) {
    let filterData: any = {'$and': [
      {'organizationId._id': { '$eq': this.requestService.orgId }}
    ]};
    const dialogRef = this.dialog.open(ModalTileCreateDialogComponent, {
      width: '100vw',
      data: {
        title: this.translate.instant('Create') + ' ' + this.translate.instant('Tile'),
        data: {}
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let currentSettings = JSON.parse(JSON.stringify(this.settingObject[this.viewMode]));
        cmp['metadata']['id'] = result['_id'];
        cmp['metadata']['name'] = result['name'];
        currentSettings['columns'][0]['components'].push(cmp);
        this.data.data['settings'][this.viewMode] = JSON.parse(JSON.stringify(currentSettings));
        this.cleanSettings();
        this.selectChildLastComponent();
        this.openedLeftMenu = '';
      }
    });
  }
  /**
  * Add People Function
  */
  addPeople(ppl) {
    if (ppl === 'lead') {
      this.settingUsers('lead', 'Lead', this.data, true);
    } else if (ppl === 'guest') {
      this.settingUsers('guest', 'Guest', this.data, false);
    } else if (ppl === 'attendee') {
      this.settingUsers('attendee', 'Attendee', this.data, false);
    } else if (ppl === 'moderator') {
      this.settingUsers('moderator', 'Moderator', this.data, true);
    }
    this.openedLeftMenu='';
  }
  settingUsers(type, displayType, event, showHost) {
    const dialogRef = this.dialog.open(SessionAssignDialogComponent, {
      width: '1600px',
      data: {
        targetDataType: 'resource/user',
        targetDataTypeDisplay: this.translate.instant(displayType),
        dataType: this.data.dataType,
        title: 'Assign ' + this.translate.instant(type) + ' to ' + event.data.name,
        columnData: type,
        data: event.data,
        showHost: showHost,
        filters: {
          '$and': [
            { 'organizationId._id': { '$eq': this.requestService.orgId } }
          ]
        },
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.data.data.users = result.users;
        if (this.data.data.users && type === 'guest') {
          let guests = this.data.data.users.filter((user) => user.type === 'guest');
          this.guestsTotal = guests.length;
          let currentSettings = JSON.parse(JSON.stringify(this.settingObject['desktop-hls']));
          this.updateAddScreenShare(currentSettings['addScreenShare']);
        }
      }
    });
  }
}
