import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { RequestService } from "./request.service";

declare var Ably: any;

@Injectable()
export class AblyService {

    ably: any = undefined;
    private channelName: string = 'global';
    channel: any = undefined;
    myChannel: any = undefined;
    connectedChannels: any = [];

    constructor(private requestService: RequestService) { }

    init(userId: string) {
        // this.requestService.getAblyToken((data, error) => {
        //     if (data.status && data.token) {
        //         console.log(data)

        //     }
        // });
        this.ably = new Ably.Realtime({
            log: { level: 1 },
            authUrl: environment.serverUrl + 'resource/auth', authHeaders: {
                'Authorization': this.requestService.getToken()
            },
            clientId: userId
        });
        console.log('ably', this.ably)
    }

    getGlobalChannel() {
        this.channel = this.getChannel(this.channelName);
    }

    getOwnChannel(user: any) {
        this.myChannel = this.getChannel('private-' + user._id);
        // this.myChannel.presence.subscribe('enter', (member) => {
        //     alert('Member ' + member.clientId + ' entered');
        //   });
        let presenceObj = this.buildPresence(user);

        // this.myChannel.attach((data) => {
        //     this.myChannel.presence.enter(presenceObj);
        // });
        this.myChannel.presence.enter(presenceObj);
        console.log('my channel', this.myChannel, user._id, presenceObj, this.requestService.orgId);

        // this.myChannel.history((error, resultPage) => {
        //     console.log("Last message published: ", resultPage);
        //     // resultPage.items.forEach(element => {
        //     //     if (this.opentokService.chatMessages[this.chatPrivatelyWith.userId]) {
        //     //         this.opentokService.chatMessages[<string>this.chatPrivatelyWith.userId].unshift({ message: element, color: this.color, sender: undefined, missedCall: true });
        //     //     }
        //     //     else {
        //     //         this.opentokService.chatMessages[this.chatPrivatelyWith.userId] = [{ message: this.translate.instant('You Joined the call'), color: this.color, sender: undefined, missedCall: true }];
        //     //     }
        //     // });
        // });
    }

    private buildPresence(user: any): any {
        let presenceObj;
        let nickname;
        if (user.hasOwnProperty('nickName') && user['nickName'])
            nickname = user['nickName'];
        else
            nickname = user['firstName'] + ' ' + user['lastName'].substr(0, 1);
        presenceObj = { 'userId': user['_id'], 'name': user['name'], 'nickname': nickname };

        if (user['chat_dnd'] == undefined)
            presenceObj['chat_dnd'] = false;
        else
            presenceObj['chat_dnd'] = user['chat_dnd'];

        if (user['isTextChat'] == undefined)
            presenceObj['showTextChat'] = false;
        else
            presenceObj['showTextChat'] = user['isTextChat'];

        if (user['isVideoChat'] == undefined)
            presenceObj['showVideoChat'] = false;
        else
            presenceObj['showVideoChat'] = user['isVideoChat'];
        return presenceObj;
    }

    getChannel(channelName: string) {
        let channelIdx = this.connectedChannels.findIndex(i => i.name === channelName);
        if (channelIdx !== -1)
            return this.connectedChannels[channelIdx];
        else {
            let channel = this.ably.channels.get(channelName + '-' + this.requestService.orgId);
            this.connectedChannels.push(channel);
            return channel;
        }
    }

    publish(messageName: string, payload: any, channel: any = this.channel) {
        channel.publish(messageName, payload, (error) => {
            if (error) {
                console.log('error publishing ably', error);
            }
        });
    }

    subscribe(messageName: string, callBack: (message) => void, channel: any = this.channel) {
        channel.subscribe(messageName, (message) => {
            console.log(message);
            callBack(message.data);
        }, (error) => {
            if (error) {
                console.log('error publishing ably', error);
            }
        });
    }

    quickPublishToPrivateChannel(userId: string, messageType: string, payload: any) {
        let channel = this.getChannel('private-' + userId);
        if (channel) {
            this.publish(messageType, payload, channel);
        }
    }

    sendSessionSignal(sessionId: string, messageType: string, payload: any) {
        let channel = this.getChannel('session-' + sessionId);
        if (channel) {
            this.publish(messageType, payload, channel);
        }
    }

    sendRoomSignal(roomId: string, messageType: string, payload: any) {
        let channel = this.getChannel('room-' + roomId);
        if (channel) {
            this.publish(messageType, payload, channel);
        }
    }

    subscribeToSessionSignals(messageName: string, callBack: (message) => void, sessionId: any) {
        this.subscribe(messageName, callBack, this.getChannel('session-' + sessionId));
    }

    subscribeToRoomSignals(messageName: string, callBack: (message) => void, roomId: any) {
        this.subscribe(messageName, callBack, this.getChannel('room-' + roomId));
    }

    connectToPrivateUserChannelAndGetPresence(userId: string) {
        let channel = this.getChannel('private-' + userId);
        channel.presence.subscribe((member) => {
            console.log(member);
        });
    }

    sendUpdateToUsersInMyChannel(user: any) {
        let presenceObj = this.buildPresence(user);
        this.myChannel.presence.update(presenceObj);
    }
}