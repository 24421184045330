import { environment } from '../environments/environment';
export class MenuConfig {
  public defaults: any = {
    aside: {
      self: {},
      items: [
        // {
        //   title: "Dashboard",
        //   root: true,
        //   icon: "dashboard",
        //   page: "/dashboard",
        //   bullet: "dot"
        // },
        {
          title: "My Rooms",
          root: true,
          icon: "room_preferences",
          page: "/rooms/directory",
          bullet: "dot"
        },
        {
          title: "My Library",
          root: true,
          icon: "widgets",
          page: "/tiles",
          bullet: "dot",
          permission: [environment.customKeys.roleAdmin, environment.customKeys.roleEdit],
        },
        {
          title: "StellarConnect",
          root: true,
          icon: "recent_actors",
          page: "/book",
          bullet: "dot",
          permission: [environment.customKeys.roleAdmin, environment.customKeys.roleEdit],
        },
        {
          title: 'Broadcast',
          root: true,
          icon: "campaign",
          page: '/broadcast',
          bullet: "dot",
          permission: [environment.customKeys.roleAdmin, environment.customKeys.roleEdit]
        },
        {
          title: 'Admin',
          root: true,
          bullet: 'dot',
          icon: 'add',
          target: 'admin',
          permission: [environment.customKeys.roleAdmin],
          submenu: [
            {
              title: 'Users',
              page: '/admin/users',
            },
            {
              title: 'Rooms',
              page: '/admin/rooms'
            },
            // {
            //   title: 'Template Layouts',
            //   page: '/admin/layouts'
            // },
            {
              title: 'Billboards',
              page: '/admin/billboards'
            },
            {
              title: 'Map Area',
              page: '/admin/maparea'
            },
            {
              title: 'Resources',
              page: '/admin/resources'
            }
          ]
        }
      ]
    }
  };

  public get configs(): any {
    return this.defaults;
  }
}
