export class LayoutComponents {
  public static componentsName: any = {
    camera: {
      displayName: 'Attendee Camera',
      fixed: false,
      rules: {
        ratioSize: {w: 16, h: 9},
        resizeEdges: { bottom: true, right: true, top: false, left: false }
      },
      content: {
        name: 'camera',
        active: true,
        enableAllowAllSpeak: false,
        x: 0,
        y: 0,
        w: 16,
        h: 19,
        z: 1
      }
    },
    'guest-camera': {
      displayName: 'Guest Camera',
      fixed: true,
      rules: {
        ratioSize: {w: 16, h: 9},
        resizeEdges: { bottom: true, right: true, top: false, left: false }
      },
      content: {
        name: 'guest-camera',
        active: true,
        metadata: {
          id: '',
          name: ''
        },
        x: 0,
        y: 0,
        w: 16,
        h: 19,
        z: 1
      }
    },
    attendees: {
      displayName: 'Attendees',
      fixed: false,
      rules: {
        ratioSize: undefined,
        resizeEdges: { bottom: true, right: true, top: false, left: false }
      },
      content: {
        name: 'attendees',
        active: true,
        expanded: false,
        backgroundColor: '#ffffff',
        x: 0,
        y: 0,
        w: 10,
        h: 10,
        z: 1
      }
    },
    defaultvideo: {
      displayName: '3rd Party Streaming',
      fixed: false,
      rules: {
        ratioSize: {w: 16, h: 9},
        resizeEdges: { bottom: true, right: true, top: false, left: false }
      },
      content: {
        name: 'defaultvideo',
        active: true,
        autoStart: false,
        frame: '',
        url: '',
        x: 0,
        y: 0,
        w: 16,
        h: 19,
        z: 1
      }
    },
    screenshare: {
      displayName: 'Screenshare',
      fixed: false,
      rules: {
        ratioSize: {w: 16, h: 9},
        resizeEdges: { bottom: true, right: true, top: false, left: false }
      },
      content: {
        name: 'screenshare',
        active: true,
        message: '',
        x: 0,
        y: 0,
        w: 16,
        h: 19,
        z: 1
      }
    },
    'presenter-camera': {
      displayName: 'Lead Camera',
      fixed: false,
      rules: {
        ratioSize: {w: 16, h: 9},
        resizeEdges: { bottom: true, right: true, top: false, left: false }
      },
      content: {
        name: 'presenter-camera',
        active: true,
        viewPublicChat: false,
        x: 0,
        y: 0,
        w: 16,
        h: 19,
        z: 1
      }
    },
    tile: {
      displayName: 'Tile',
      fixed: true,
      rules: {
        ratioSize: undefined,
        resizeEdges: { bottom: true, right: true, top: false, left: false }
      },
      content: {
        name: 'tile',
        metadata: {
          id: '',
          name: ''
        },
        active: true,
        x: 0,
        y: 0,
        w: 10,
        h: 10,
        z: 1
      }
    },
    billboard: {
      displayName: 'Billboard',
      fixed: true,
      rules: {
        ratioSize: undefined,
        resizeEdges: { bottom: true, right: true, top: false, left: false }
      },
      content: {
        name: 'billboard',
        metadata: {
          id: '',
          name: ''
        },
        active: true,
        x: 0,
        y: 0,
        w: 10,
        h: 10,
        z: 1
      }
    },
    editor: {
      displayName: 'Editor',
      fixed: true,
      rules: {
        ratioSize: undefined,
        resizeEdges: { bottom: true, right: true, top: false, left: false }
      },
      content: {
        name: 'editor',
        data: '',
        active: true,
        x: 0,
        y: 0,
        w: 10,
        h: 10,
        z: 1,
        bgcolor: ''
      }
    },
    questionnaire: {
      displayName: 'Questionnaire',
      fixed: true,
      rules: {
        ratioSize: undefined,
        resizeEdges: { bottom: true, right: true, top: false, left: false }
      },
      content: {
        name: 'questionnaire',
        metadata: {
          id: '',
          name: ''
        },
        active: true,
        x: 0,
        y: 0,
        w: 10,
        h: 10,
        z: 1
      }
    },
    linkDescription: {
      displayName: 'Link with Description',
      fixed: true,
      rules: {
        ratioSize: undefined,
        resizeEdges: { bottom: true, right: true, top: false, left: false }
      },
      content: {
        name: 'linkDescription',
        metadata: {
          id: '',
          name: ''
        },
        active: true,
        x: 0,
        y: 0,
        w: 10,
        h: 10,
        z: 1
      }
    },
    inquiry: {
      displayName: 'Inquiry',
      fixed: true,
      rules: {
        ratioSize: undefined,
        resizeEdges: { bottom: true, right: true, top: false, left: false }
      },
      content: {
        name: 'inquiry',
        metadata: {
          id: '',
          name: ''
        },
        active: true,
        x: 0,
        y: 0,
        w: 10,
        h: 10,
        z: 1
      }
    }
  };
  public static componentsNameKeys: string[] = Object.keys(LayoutComponents.componentsName);
}
