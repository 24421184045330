import { Component, OnInit, Input, Output, EventEmitter, Renderer2, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { RequestService, LayoutUtilsService, LoaderService } from '../../../../shared/services';
import { LayoutComponents, DefaultSettings } from '../';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { TileSelectDialogComponent } from '../../../../shared/components/tile-select-dialog/tile-select-dialog.component';
import { BillboardSelectDialogComponent } from '../../../../shared/components/billboard-select-dialog/billboard-select-dialog.component';
import { ConfirmSelectEntityDialogComponent } from '../../../../shared/components/modals';
import { MatDialog } from '@angular/material/dialog';
declare var CKEDITOR;

@Component({
    selector: 'app-screen-layout',
    templateUrl: './screen-layout.component.html',
    styleUrls: ['./screen-layout.component.scss']
})
export class ScreenLayoutComponent implements OnInit {
  public subscriptions: any[] = <any>[];
  public defaultSetting = DefaultSettings.defaultSetting;

  public componentsType: any = DefaultSettings.componentsType;
  public cameraComponents: string[] = DefaultSettings.cameraComponents;
  public tileComponents: string[] = DefaultSettings.tileComponents;
  public componentsName: any = LayoutComponents.componentsName;
  public componentsNameKeys: any = LayoutComponents.componentsNameKeys;


  public selectedColumn = undefined;
  public selectedComponent = undefined;
  public liveXY = {name : '', x: 0, y: 0, c: 0, m: 0, w: 0, h: 0, z: 1, bgcolor: ''};

  public _settingObject: any = undefined;

  editorConfig: any = {
      toolbar: [
          { name: 'document', items: ['Source', '-', 'Undo', 'Redo', '-','Bold', 'Italic', 'Underline', 'Strike', '-', 'Link', 'Unlink','TextColor', 'BGColor', '-', 'Styles', 'Format', 'Font', 'FontSize'] }
      ],
      height: '33vh',
      shiftEnterMode: CKEDITOR.ENTER_DIV,
      enterMode: CKEDITOR.ENTER_DIV,
      removePlugins: 'magicline',
      linkShowAdvancedTab: false,
      linkShowTargetTab: false,
      resize_enabled: false
  };


  @Input()
  set settingObject(settingObject: any) {
    if(settingObject){
      if(this._settingObject){
        this.updateData(settingObject);
        // this._settingObject = {...this._settingObject, ...settingObject};
      }else{
        this._settingObject = settingObject;
      }
    }else{
      this._settingObject = settingObject;
    }
  }
  get settingObject(): any {
      return this._settingObject;
  }

  @Input() data: any = undefined;
  @Output() setSelectComponent = new EventEmitter<any>();
  @Output() setUpdateLayout = new EventEmitter<any>();
  constructor(private layoutUtilsService: LayoutUtilsService, private requestService: RequestService, private translate: TranslateService, public renderer: Renderer2,
  public dialog: MatDialog, private router: Router, private activatedRoute: ActivatedRoute, private changeDetectorRef: ChangeDetectorRef, private loaderService: LoaderService) {}

  ngOnInit() {
  }
  /**
   * On Destroy
   */
  ngOnDestroy() {
    this.subscriptions.forEach(el => el.unsubscribe());
  }
  // dragDropped($event, target) {
  //   let position = $event.source.getFreeDragPosition();
  //   console.log('dragDropped $event', $event);
  //   console.log('dragDroppedposition', position);
  // }
  updateData(settingObject){
    // console.log('settingObject', settingObject);
    let idx = 0;
    for(let cmp of settingObject.columns[0]['components']){
      if(this._settingObject.columns[0]['components'][idx]){
        let settingObjectKeys = Object.keys(cmp);
        for(let key of settingObjectKeys){
          if(this._settingObject.columns[0]['components'][idx][key] !== cmp[key]){
            this._settingObject.columns[0]['components'][idx][key] = cmp[key];
          }
        }
      }else{
        this._settingObject.columns[0]['components'].push(cmp);
      }
      idx++;
    }
    // console.log('this.settingObject', this.settingObject);
  }
  dragMoved($event, mainBoundary, target) {
    // console.log('dragMoved. ',$event);
    let position = $event.source.getFreeDragPosition();
    this.liveXY['x'] = Math.round((position.x * 100) / mainBoundary.offsetWidth);
    this.liveXY['y'] = Math.round((position.y * 100) / mainBoundary.offsetWidth);
    this.liveXY['c'] = Math.round(((position.x * 100) / mainBoundary.offsetWidth) + (target.w/2));
    this.liveXY['m'] = Math.round(((position.y * 100) / mainBoundary.offsetWidth) + (target.h/2));
    this.setSelectComponent.emit({selectedColumn: this.selectedColumn, selectedComponent: this.selectedComponent, liveXY: this.liveXY});
  }
  applyResizing($event, mainBoundary, target) {
    this.liveXY['w'] = Math.round(($event.rectangle.width * 100) / mainBoundary.offsetWidth);
    this.liveXY['h'] = Math.round(($event.rectangle.height * 100) / mainBoundary.offsetWidth);
    this.setSelectComponent.emit({selectedColumn: this.selectedColumn, selectedComponent: this.selectedComponent, liveXY: this.liveXY});
  }
  dragStarted($event, index, subIndex) {
    let currentSettings = JSON.parse(JSON.stringify(this.settingObject['columns'][index]['components'][subIndex]));
    this.selectedColumn = index;
    this.selectedComponent = subIndex;
    this.liveXY = {name : currentSettings['name'], x: currentSettings['x'], y: currentSettings['y'], c: Math.round(currentSettings['x'] + (currentSettings['w']/2)), m: Math.round(currentSettings['y'] + (currentSettings['h']/2)), w: currentSettings['w'], h: currentSettings['h'], z: currentSettings['z'], bgcolor: currentSettings['bgcolor']};
    this.setSelectComponent.emit({selectedColumn: index, selectedComponent: subIndex, liveXY: this.liveXY});
  }
  dragEntered($event, index, subIndex) {
    this.selectedColumn = index;
    this.selectedComponent = subIndex;
  }
  selectComponent($event, index, subIndex) {
    if(index !== undefined && subIndex !== undefined){
      if($event){
        $event.preventDefault();
        $event.stopPropagation();
      }
      let currentSettings = JSON.parse(JSON.stringify(this.settingObject['columns'][index]['components'][subIndex]));
      this.selectedColumn = index;
      this.selectedComponent = subIndex;
      this.liveXY = {name : currentSettings['name'], x: currentSettings['x'], c: Math.round(currentSettings['x'] + (currentSettings['w']/2)), m: Math.round(currentSettings['y'] + (currentSettings['h']/2)), y: currentSettings['y'], w: currentSettings['w'], h: currentSettings['h'], z: currentSettings['z'], bgcolor: currentSettings['bgcolor']};
      this.setSelectComponent.emit({selectedColumn: index, selectedComponent: subIndex, liveXY: this.liveXY});
    }else{
      this.selectedColumn = index;
      this.selectedComponent = subIndex;
      this.liveXY = {name : '', x: 0, y: 0, c: 0, m: 0, w: 0, h: 0, z: 1, bgcolor: ''};
      this.setSelectComponent.emit({selectedColumn: index, selectedComponent: subIndex, liveXY: this.liveXY});
    }
  }
  dragEnd($event, mainBoundary, target, index, subIndex) {
    let position = $event.source.getFreeDragPosition();
    target['x'] = Math.round((position.x * 100) / mainBoundary.offsetWidth);
    target['y'] = Math.round((position.y * 100) / mainBoundary.offsetWidth);
    // this.selectedColumn = undefined;
    // this.selectedComponent = undefined;
    let currentSettings = JSON.parse(JSON.stringify(this.settingObject));
    currentSettings['columns'][index]['components'][subIndex]['x'] = target['x'];
    currentSettings['columns'][index]['components'][subIndex]['y'] = target['y'];
    this.setUpdateLayout.emit({currentSettings: currentSettings});
  }
  onResizeEnd($event, mainBoundary, target, index, subIndex) {
      target.w = Math.round(($event.rectangle.width * 100) / mainBoundary.offsetWidth);
      target.h = Math.round(($event.rectangle.height  * 100) / mainBoundary.offsetWidth);
      this.liveXY['w'] = target.w;
      this.liveXY['h'] = target.h;
      let currentSettings = JSON.parse(JSON.stringify(this.settingObject));
      currentSettings['columns'][index]['components'][subIndex]['w'] = target['w'];
      currentSettings['columns'][index]['components'][subIndex]['h'] = target['h'];
      this.setUpdateLayout.emit({currentSettings: currentSettings});
  }
  getHeight(h, w, ratioSize) {
    if(ratioSize){
      return Math.round((w * ratioSize.h) / ratioSize.w) + 'vw';
    }else{
      return h + 'vw';
    }
  }
  getZ(z, index, subIndex) {
    if(this.selectedColumn === index && this.selectedComponent === subIndex){
      return 800;
    }else{
      return z;
    }
  }
  getX(x, mainBoundary) {
    return Math.round((x * mainBoundary.offsetWidth)/100);
  }
  getY(y, mainBoundary) {
    return Math.round((y * mainBoundary.offsetWidth)/100);
  }
  getTileLink(id) {
    return this.requestService.getTileLink(undefined, id, true);
  }
  changeRowAllowAllSpeak($event, value, index, subIndex) {
    if($event){
      $event.preventDefault();
      $event.stopPropagation();
    }
    let currentSettings = JSON.parse(JSON.stringify(this.settingObject));
    currentSettings['columns'][index]['components'][subIndex]['enableAllowAllSpeak'] = value;
    this.setUpdateLayout.emit({currentSettings: currentSettings});
  }
  removeCmp($event, index, subIndex) {
    if($event){
      $event.preventDefault();
      $event.stopPropagation();
    }
    this.settingObject['columns'][index]['components'].splice(subIndex, 1);
    let currentSettings = JSON.parse(JSON.stringify(this.settingObject));
    // currentSettings['columns'][index]['components'].splice(subIndex, 1);
    this.setUpdateLayout.emit({currentSettings: currentSettings});
  }

  editTile($event, index, subIndex) {
    if($event){
      $event.preventDefault();
      $event.stopPropagation();
    }
    const dialogRef = this.dialog.open(TileSelectDialogComponent, {
      width: '600px',
      data: {
        title: this.translate.instant('Select') + ' ' + this.translate.instant('Tile'),
        data: {},
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let currentSettings = JSON.parse(JSON.stringify(this.settingObject));
        if (result.hasOwnProperty('_id')) {
          currentSettings['columns'][index]['components'][subIndex]['metadata']['id'] = result['_id'];
          currentSettings['columns'][index]['components'][subIndex]['metadata']['name'] = result['title'];
        } else {
          currentSettings['columns'][index]['components'][subIndex]['metadata']['id'] = '';
          currentSettings['columns'][index]['components'][subIndex]['metadata']['name'] = '';
        }
        this.setUpdateLayout.emit({currentSettings: currentSettings});
      }
    });
  }
  editBillboard($event, index, subIndex) {
    if($event){
      $event.preventDefault();
      $event.stopPropagation();
    }
    let currentSettings = JSON.parse(JSON.stringify(this.settingObject));
    const dialogRef = this.dialog.open(BillboardSelectDialogComponent, {
      width: '600px',
      data: {
        title: this.translate.instant('Select') + ' ' + this.translate.instant('Billboard'),
        data: currentSettings['columns'][index]['components'][subIndex],
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        //console.log('result', result);
        if (result.hasOwnProperty('_id')) {
          currentSettings['columns'][index]['components'][subIndex]['metadata']['id'] = result['_id'];
          if (result.hasOwnProperty('text')) {
            currentSettings['columns'][index]['components'][subIndex]['metadata']['name'] = result['text'];
          } else {
            currentSettings['columns'][index]['components'][subIndex]['metadata']['name'] = result['title'];
          }
        } else {
          currentSettings['columns'][index]['components'][subIndex]['metadata']['id'] = '';
          currentSettings['columns'][index]['components'][subIndex]['metadata']['name'] = '';
        }
        this.setUpdateLayout.emit({currentSettings: currentSettings});
      }
    });
  }
  getSelectedUsers(cameraType) {
    let alreadySelected = [];
    let settingObject = JSON.parse(JSON.stringify(this.settingObject));
    for (let col of settingObject['columns']) {
      for (let comp of col['components']) {
        if (comp.name === cameraType && comp.metadata.hasOwnProperty('id') && comp.metadata.id !== '') {
          alreadySelected.push(comp.metadata.id);
        }
      }
    }
    return alreadySelected;
  }
  /**
  * Edit Guest function
  */
  editGuest($event, index, subIndex) {
    if($event){
      $event.preventDefault();
      $event.stopPropagation();
    }
    let currentSettings = JSON.parse(JSON.stringify(this.settingObject));

    let alreadySelected = this.getSelectedUsers('guest-camera' );

    let guestsList = [];
    if (this.data.data.hasOwnProperty('users')) {
      for (let dt of this.data.data.users) {
        if (dt.type === 'guest') {
          guestsList.push({ _id: dt._id, text: dt.name });
        }
      }
    }

    // console.log('guestsList', guestsList);
    const dialogRef = this.dialog.open(ConfirmSelectEntityDialogComponent, {
      width: '600px',
      data: {
        title: this.translate.instant('Select') + ' ' + this.translate.instant('Guest'),
        data: currentSettings['columns'][index]['components'][subIndex]['metadata']['id'],
        dataList: guestsList,
        placeholder: 'Select ' + this.translate.instant('Guest'),
        cancelbtn: 'Cancel',
        confirmbtn: 'Confirm',
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        //console.log('result', result);
        if (result.hasOwnProperty('_id')) {
          currentSettings['columns'][index]['components'][subIndex]['metadata']['id'] = result['_id'];
          if (result.hasOwnProperty('text')) {
            currentSettings['columns'][index]['components'][subIndex]['metadata']['name'] = result['text'];
          } else {
            currentSettings['columns'][index]['components'][subIndex]['metadata']['name'] = result['title'];
          }
        } else {
          currentSettings['columns'][index]['components'][subIndex]['metadata']['id'] = '';
          currentSettings['columns'][index]['components'][subIndex]['metadata']['name'] = '';
        }
        this.setUpdateLayout.emit({currentSettings: currentSettings});
      }
    });
  }
  /**
  * Active / deActivate function
  */
  editActive($event, index, subIndex, value) {
    if($event){
      $event.preventDefault();
      $event.stopPropagation();
    }
    let currentSettings = JSON.parse(JSON.stringify(this.settingObject));
    currentSettings['columns'][index]['components'][subIndex]['active'] = value;
    this.setUpdateLayout.emit({currentSettings: currentSettings});
  }

  /**
  * editAttribute function
  */
  editAttribute($event, index, subIndex, id, value) {
    if($event){
      $event.preventDefault();
      $event.stopPropagation();
    }
    let currentSettings = JSON.parse(JSON.stringify(this.settingObject));
    currentSettings['columns'][index]['components'][subIndex][id] = value;
    this.setUpdateLayout.emit({currentSettings: currentSettings});
  }
  /**
  * editAttribute function
  */
  editNumericAttribute($event, index, subIndex, id, value, min, max) {
    if($event){
      $event.preventDefault();
      $event.stopPropagation();
    }
    let newVal = Number(value);
    let currentSettings = JSON.parse(JSON.stringify(this.settingObject));
    if(min !== undefined && min > newVal){
      newVal = min;
    }
    if(max !== undefined && max < newVal){
      newVal = max;
    }
    currentSettings['columns'][index]['components'][subIndex][id] = newVal + '';
    this.setUpdateLayout.emit({currentSettings: currentSettings});
    this.changeDetectorRef.detectChanges();
  }

  /**
  * editEditor function
  */
  editEditor(index, subIndex, id, value) {
    console.log(value);
    let currentSettings = JSON.parse(JSON.stringify(this.settingObject));
    currentSettings['columns'][index]['components'][subIndex][id] = value;
    console.log(currentSettings['columns'][index]['components'][subIndex]);
    // this.settingObject['columns'][index]['components'][subIndex]['active'] = value;
    this.setUpdateLayout.emit({currentSettings: currentSettings});
  }
  cancelThru($event) {
    if($event){
      $event.preventDefault();
      $event.stopPropagation();
    }
  }
}
