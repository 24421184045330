<div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px" *ngIf="settingObject" >
    <div fxFlex cdkDropListGroup>
      <div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px" >
        <div fxFlex>
          <div class="overlayLayout example-boundary" #mainBoundary (click)="selectComponent($event, undefined, undefined)">
            <div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="0VW" class="bgComponent">
              <div fxFlex *ngFor="let col of settingObject['columns'];let index = index"  class="example-list" [class.highZIndex] = "selectedColumn === index">
                  <div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px">
                    <div fxFlex
                    id="rowsmenu{{index}}" name="rowsmenu" class="example-box-row" >
                      <div class="example-box" *ngFor="let cmp of col['components'];let subIndex = index" cdkDrag cdkDragBoundary=".example-boundary" [cdkDragFreeDragPosition]="{x: getX(cmp.x, mainBoundary), y: getY(cmp.y, mainBoundary)}" (cdkDragEnded)="dragEnd($event, mainBoundary, cmp, index, subIndex)" (cdkDragMoved)="dragMoved($event, mainBoundary, cmp)" (cdkDragStarted)="dragStarted($event, index, subIndex)" (cdkDragEntered)="dragEntered($event, index)"
                      mwlResizable [enableGhostResize]="true" [resizeCursorPrecision]="5" [resizeEdges]="componentsName[cmp.name].rules.resizeEdges" (resizeStart)="selectComponent(undefined, index, subIndex)" (resizing)="applyResizing($event, mainBoundary, cmp)" [resizeRatio]="componentsName[cmp.name].rules.ratioSize" (resizeEnd)="onResizeEnd($event, mainBoundary, cmp, index, subIndex)"  [ngStyle]="{'z-index': getZ(cmp.z, index, subIndex), 'height' : getHeight(cmp.h,cmp.w, componentsName[cmp.name].rules.ratioSize), 'width' : cmp.w+'vw', 'backgroundColor': cmp.bgcolor}" (click)="selectComponent($event, index, subIndex)" [class.selectedComponent]="selectedColumn === index && selectedComponent === subIndex" [class.cameraComponent]="cameraComponents.includes(cmp.name)" [class.editorClass]="cmp.name === 'editor'">
                        <div class="example-box-handle" cdkDragHandle>
                          <div class="contentDataActions" [class.contentDataActions-top]="((cmp.y * mainBoundary.offsetWidth)/100) < 20">
                            <!-- <button mat-icon-button color="primary" *ngIf="cmp['name'] === 'editor'" (click)="cmp['preview'] = !(cmp['preview'])" matTooltip="{{'Edit' | translate}}" matTooltipClass="tooltip-red">
                              <mat-icon *ngIf="cmp['preview']">preview</mat-icon>
                              <mat-icon *ngIf="!cmp['preview']">edit</mat-icon>
                            </button> -->

                            <button mat-icon-button color="primary" *ngIf="tileComponents.includes(cmp.name)" (click)="editTile($event, index, subIndex)" matTooltip="Select {{'Tile' | translate}}" matTooltipClass="tooltip-red">
                              <mat-icon>select_all</mat-icon>
                            </button>
                            <!-- <span *ngIf="cmp['name'] === 'guest-camera' && cmp.metadata.name !== ''">{{cmp.metadata.name}}</span> -->
                            <button mat-icon-button color="primary"  *ngIf="cmp['name'] === 'guest-camera'" (click)="editGuest($event, index, subIndex)" [class.selectedUser]="cmp.metadata.name !== ''" matTooltip="Select {{'Guest' | translate}}" matTooltipClass="tooltip-red">
                              <mat-icon>supervisor_account</mat-icon>
                            </button>
                            <!-- <span *ngIf="cmp['name'] === 'billboard' && cmp.metadata.name !== ''">{{cmp.metadata.name}}</span> -->
                            <button mat-icon-button color="primary" *ngIf="cmp['name'] === 'billboard'" (click)="editBillboard($event, index, subIndex)" matTooltip="{{'Select Billboard' | translate}}" matTooltipClass="tooltip-red">
                              <mat-icon>select_all</mat-icon>
                            </button>
                            <button mat-icon-button color="primary" (click)="editActive($event, index, subIndex, !cmp['active'])" matTooltip="{{!cmp['active'] ? 'Activate' : 'Deactivate'}}" matTooltipClass="tooltip-red">
                              <mat-icon *ngIf="!cmp['active']">visibility_off</mat-icon>
                              <mat-icon *ngIf="cmp['active']">visibility</mat-icon>
                            </button>
                            <button mat-icon-button color="primary" (click)="removeCmp($event, index, subIndex)" matTooltip="{{'Delete' | translate}}" matTooltipClass="tooltip-red">
          									  <mat-icon>delete_forever</mat-icon>
          									</button>
                          </div>
                          <!--<div class="contentDataTitle">
                            <div>{{componentsName[cmp.name]['displayName']}}</div>
                             <div *ngIf="!(selectedColumn === index && selectedComponent === subIndex) ">x: {{cmp.x}}, y: {{cmp.y}}</div>
                            <div *ngIf="selectedColumn === index && selectedComponent === subIndex ">x: {{liveXY.x}}, y: {{liveXY.y}}</div>
                          </div>-->
                          <!-- <div class="contentDataBG" *ngIf="cameraComponents.includes(cmp.name)">
                            <mat-icon>videocam</mat-icon>
                          </div> -->
                          <div class="contentRealData">
                            <div class="contentDataMain" *ngIf="!((cmp['name'] === 'editor' || cmp['name'] === 'billboard' || tileComponents.includes(cmp.name)) )">
                              <div class="contentData" *ngIf="cmp['name'] === 'defaultvideo'">
                                <mat-form-field style="width: 100%;">
                                    <input matInput placeholder="{{ 'Video Url' | translate }}" [value]="cmp['url']" (change)="editAttribute($event, index, subIndex, 'url', $event.target.value)">
                                </mat-form-field>
                                <!-- <label for="url{{subIndex}}">{{'Video Url' | translate}}:</label>
                                <input name="url{{subIndex}}" placeholder="" [value]="cmp['url']" (change)="cmp['url']=$event.target.value"> -->
                              </div>
                              <!-- <div class="contentData" *ngIf="cmp['name'] === 'presenter-camera'">
                                <mat-icon *ngIf="!cmp['viewPublicChat']" (click)="cmp['viewPublicChat']=true" title="{{'View public chat' | translate}}" style="height: 30px;vertical-align: middle;">check_box_outline_blank</mat-icon>
                                <mat-icon *ngIf="cmp['viewPublicChat']" (click)="cmp['viewPublicChat']=false" title="{{'View public chat' | translate}}" style="height: 30px;vertical-align: middle;">check_box</mat-icon>
                                <label style="width: 13vw" for="viewPublicChat{{subIndex}}"> {{'View public chat' | translate}}</label>
                              </div> -->
                              <div class="contentData" *ngIf="cmp['name'] === 'camera'">
                                <mat-icon *ngIf="!cmp['enableAllowAllSpeak']" (click)="changeRowAllowAllSpeak($event, true, index, subIndex )" title="{{'All attendees can speak at the same time' | translate}}" style="height: 30px;vertical-align: middle;">check_box_outline_blank</mat-icon>
                                <mat-icon *ngIf="cmp['enableAllowAllSpeak']" (click)="changeRowAllowAllSpeak($event, false, index, subIndex )" title="{{'All attendees can speak at the same time' | translate}}" style="height: 30px;vertical-align: middle;">check_box</mat-icon>
                                <label for="enableAllowAllSpeak{{subIndex}}" style="width: 13vw;">{{'All attendees can speak at the same time' | translate}}</label>
                              </div>
                              <div class="contentData" *ngIf="cmp['name'] === 'defaultvideo'">
                                <mat-form-field style="width: 100%;">
                                    <input matInput type="number" min="0" placeholder="{{ 'Border Thickness' | translate }}" [(value)]="cmp['frame']" (change)="editNumericAttribute($event, index, subIndex, 'frame', $event.target.value, 0, 100)">
                                </mat-form-field>
                              </div>
                              <div class="contentData" *ngIf="cmp['name'] === 'screenshare'">
                                <mat-form-field style="width: 100%;">
                                    <input matInput placeholder="{{ 'Message' | translate }}" [value]="cmp['message']" (change)="editAttribute($event, index, subIndex, 'message', $event.target.value)">
                                </mat-form-field>
                              </div>
                              <div class="contentData" *ngIf="cmp['name'] === 'defaultvideo'">
                                <mat-icon *ngIf="!cmp['autoStart']" (click)="editAttribute($event, index, subIndex, 'autoStart', true)" title="{{'Auto Start' | translate}}" style="height: 30px;vertical-align: middle;">check_box_outline_blank</mat-icon>
                                <mat-icon *ngIf="cmp['autoStart']" (click)="editAttribute($event, index, subIndex, 'autoStart', false)" title="{{'Auto Start' | translate}}" style="height: 30px;vertical-align: middle;">check_box</mat-icon>
                                <label style="width: 13vw" for="autoStart{{subIndex}}"> {{'Auto Start' | translate}}</label>
                              </div>
                            </div>
                            <ng-container *ngIf="tileComponents.includes(cmp.name) && cmp.metadata.id !== ''">
                                <app-iframe-url [iframeLink]="getTileLink(cmp.metadata.id)" [iframeParentHeight]="getHeight(cmp.h,cmp.w, componentsName[cmp.name].rules.ratioSize)" [forEdit]="true"></app-iframe-url>
                            </ng-container>
                            <ng-container *ngIf="cmp['name'] === 'billboard' && cmp.metadata.id !== ''">
                              <app-slider [forEdit]="true" [bannerId]="cmp.metadata.id">
                              </app-slider>
                            </ng-container>
                            <ng-container *ngIf="cmp['name'] === 'editor'">
                              <div [innerHTML]="cmp.data | safeHtml"></div>
                              <div class="editorPop" [class.editorPop-left]="((cmp.x * mainBoundary.offsetWidth)/100) > (mainBoundary.offsetWidth/3)" [class.editorPop-bottom]="((cmp.y * mainBoundary.offsetWidth)/100 + (cmp.h * mainBoundary.offsetWidth)/100) > mainBoundary.offsetHeight/2">
                                <ckeditor-block *ngIf="selectedColumn === index && selectedComponent === subIndex" fxFlex [value]="cmp.data" [config]="editorConfig" (textView)="editEditor(index, subIndex, 'data', $event)">
                                </ckeditor-block>
                              </div>
                            </ng-container>
                          </div>
                        </div>
                        <div class="resize-action"></div>
                        <div class="layout" data-aid="edit-box-layout" >
                          <span class="component-labels" [class.component-labels-top]="((cmp.y * mainBoundary.offsetWidth)/100) < 20">
                            <span data-aid="display-name-label" class="label-comp-type">{{componentsName[cmp.name]['displayName'] | translate}}</span>
                          </span>
                          <span data-direction-name="topLeft" class="topLeft"></span>
                          <span data-direction-name="top" class="top"></span>
                          <span data-direction-name="topRight" class="topRight"></span>
                          <span data-direction-name="right" class="right"></span>
                          <span data-direction-name="bottomRight" class="bottomRight"></span>
                          <span data-direction-name="bottom" class="bottom"></span>
                          <span data-direction-name="bottomLeft" class="bottomLeft">
                          </span>
                          <span data-direction-name="left" class="left">
                          </span>
                          </div>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</div>
